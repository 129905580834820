import useViewportDimensions from 'src/integrations/useViewportDimensions';
const CommonStyles = {
  /* Text Styles */
  text_bold: {
    fontWeight: 'bold',
  },
  text_base: {
    fontFamily: 'asap-regular',
    color: '#5B7E96',
  },
  text_white: {
    color: '#FFFFFF',
  },
  background_yellow: {
    backgroundColor: '#D2D745',
  },
  background_blue: {
    backgroundColor: '#637D94',
  },
  background_orange: {
    backgroundColor: '#F4C174',
  },
  text_center: {
    alignSelf: 'center',
  },
  text_muted: {
    color: '#C3C3C3',
  },
  text_small: {
    fontSize: 13,
  },
  text_normal: {
    fontSize: 14,
  },
  text_big: {
    fontSize: 16,
  },
  /* ================== */
  profile: {
    flexDirection: 'row',
    padding: 20,
  },
  image: {
    borderRadius: 50,
    height: 50,
    width: 50,
  },
  card: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  card_title: {
    padding: 14,
    flexDirection: 'row',
  },
  card_title_text: {
    fontFamily: 'asap-regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 18,
    textAlign: 'center',
    color: '#5B7E96',
  },
  card_text: {
    color: 'white',
    fontSize: 25,
    fontFamily: 'asap-regular',
  },
  card_footer: {
    alignSelf: 'stretch',
    backgroundColor: '#FFFFFF',
    borderTopWidth: 1,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    borderTopWidth: 2,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  card_item: {
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    borderTopWidth: 2,
  },
  text: {
    marginTop: 'auto',
    fontFamily: 'asap-regular',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 20,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'EchoesSans-Black',
    fontSize: 25,
    textTransform: 'uppercase',
    fontWeight: '500',
    color: '#5C7E96',
    lineHeigh: 1.2,
  },
  after: {
    marginBottom: 30,
    width: '66%',
    height: useViewportDimensions(1, 'vh'),
    backgroundColor: '#D6DF2A',
    alignSelf: 'center',
  },

  /* Hotel Common */
  hotel_title: {
    fontWeight: '400',
    fontSize: 26,
  },
  hotel_address: {
    fontSize: 13,
  },
  hotel_price: {
    fontSize: 16,
    textAlign: 'right',
  },
  hotel_card: {
    marginBottom: 20,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  hotel_info: {
    padding: 20,
  },

  hotel_list_image: {
    width: '100%',
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
};
export default CommonStyles;
