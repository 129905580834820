import { View, Text, StyleSheet } from 'react-native';
import CommonStyles from 'src/styles/CommonStyles';

export default function RequestCard({ hotelName, status, checkin, checkout, requested_by_role }) {
  const statusColor = {
    approved: 'blue',
    cancelled: 'red',
    pending: 'orange',
    completed: 'green',
  };
  const statusText = {
    approved: 'Aprobada',
    cancelled: 'Cancelada',
    pending: 'Pendiente',
    completed: 'Completada',
  };
  return (
    <View style={[styles.card, { marginBottom: 20 }]}>
      <View
        style={[
          styles.card_title,
          { flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
        ]}>
        <Text style={styles.card_title_text}>{hotelName}</Text>
        <View
          style={{
            marginLeft: 'auto',
            flexDirection: 'row',
            alignItems: 'center',
            verticalAlign: '',
          }}>
          <Text style={[styles.title, { color: statusColor[status] }]}>{statusText[status]}</Text>
          <View style={[styles.icon, { backgroundColor: statusColor[status] }]} />
        </View>
      </View>
      <View style={[styles.card_footer, { padding: 14 }]}>
        <View style={styles.row}>
          <Text style={styles.text_bold}>Estancia: </Text>
          <Text style={styles.text_regular}>{`${checkin} - ${checkout}`}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text_bold}>Solicitado por: </Text>
          <Text style={styles.text_regular}>{requested_by_role === 'gekko' ? 'Tú' : 'Hotel'}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    container: {
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#ECECEC',
      marginBottom: 20,
    },
    title: {
      fontSize: 13,
    },
    header: {
      borderColor: '#ececec',
      borderStyle: 'solid',
      borderBottomWidth: 1,
      backgroundColor: '#FFFFFF',
      paddingVertical: 10,
      paddingHorizontal: 15,
      flexDirection: 'row',
      alignItems: 'center',
    },
    body: {
      paddingHorizontal: 15,
      paddingBottom: 10,
    },
    icon: {
      width: 10,
      height: 10,
      borderRadius: 100,
      marginHorizontal: 8,
      marginTop: 4,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
  },
});
