import { NavigationContainer, Dimensions } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useContext } from 'react';

/* Screens */
import GlobalContext from 'src/GlobalContext';
import GekkoRequestDetailsScreen from 'src/screens/gekko/GekkoRequestDetailsScreen';
import HotelDetailsScreen from 'src/screens/gekko/HotelDetailsScreen';
import HotelListScreen from 'src/screens/gekko/HotelListScreen';

import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';
import SplashScreen from './screens/SplashScreen';
import GekkoDashboardScreen from './screens/gekko/GekkoDashboardScreen';
import GoodbyeScreen from './screens/gekko/GoodbyeScreen';
import QuestionnairesQuestionsScreen from './screens/gekko/QuestionnairesQuestionsScreen';
import ComunityScreen from './screens/hotel/ComunityScreen';
import GekkoProfileScreen from './screens/hotel/GekkoProfileScreen';
import HotelDashboardScreen from './screens/hotel/HotelDashboardScreen';
import InformesScreen from './screens/hotel/InformesScreen';
import NewOfferScreen from './screens/hotel/NewOfferScreen';
import OfferScreen from './screens/hotel/OfferScreen';
import OfferSentScreen from './screens/hotel/OfferSentScreen';
import UserHotelierScreen from './screens/hotel/UserHotelierScreen';

/* Gekko */

const Stack = createNativeStackNavigator();

export default function Routes() {
  const { state } = useContext(GlobalContext);
  return (
    <NavigationContainer>
      <Stack.Navigator>
        {state.isLoading ? (
          // We haven't finished checking for the token yet
          <Stack.Screen name="Splash" component={SplashScreen} options={{ headerShown: false }} />
        ) : state.userToken === null || state.userToken === 'null' ? (
          // No token found, user isn't signed in
          <>
            <Stack.Screen
              name="SignIn"
              component={SignInScreen}
              options={{
                title: 'Sign in',
                // When logging out, a pop animation feels intuitive
                animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUpScreen}
              options={{
                title: 'Sign up',
                // When logging out, a pop animation feels intuitive
                animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                headerShown: false,
              }}
            />
          </>
        ) : // User is signed in
        state.userRole === 'gekko' ? (
          // Role gekko
          <>
            <Stack.Screen
              name="GekkoDashboardScreen"
              component={GekkoDashboardScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="HotelListScreen"
              component={HotelListScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="HotelDetailsScreen"
              component={HotelDetailsScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="GekkoRequestDetailsScreen"
              component={GekkoRequestDetailsScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="QuestionnairesQuestionsScreen"
              component={QuestionnairesQuestionsScreen}
              options={{ headerShown: false }}
            />

            <Stack.Screen
              name="Goodbye"
              component={GoodbyeScreen}
              options={{ headerShown: false }}
            />
          </>
        ) : (
          // Role hotelier
          <>
            <Stack.Screen
              name="HotelDashboardScreen"
              component={HotelDashboardScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="UserHotelierScreen"
              component={UserHotelierScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="ComunityScreen"
              component={ComunityScreen}
              options={{ headerShown: false }}
            />

            <Stack.Screen
              name="OfferScreen"
              component={OfferScreen}
              options={{ headerShown: false }}
            />

            <Stack.Screen
              name="NewOfferScreen"
              component={NewOfferScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="OfferSentScreen"
              component={OfferSentScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="InformesScreen"
              component={InformesScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="GekkoProfileScreen"
              component={GekkoProfileScreen}
              options={{ headerShown: false }}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
