import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, Animated } from 'react-native';
import Svg, { Circle } from 'react-native-svg';

const CIRCULAR_CIRCUMFERENCE = 280;
const CIRCLE_RADIUS = String(CIRCULAR_CIRCUMFERENCE / (2 * Math.PI));

const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const CircularProgressBar = ({ progressBar }) => {
  const stringifiedWidth = String(50);
  const stringifiedHeight = String(50);
  const [progressValue, setProgressValue] = useState(0);
  const [start, SetStart] = useState(false);

  const progress = new Animated.Value(1);

  useEffect(() => {
    Animated.timing(progress, {
      useNativeDriver: true,
      toValue: 1 - progressBar / 100,
      duration: 2000,
      delay: 200,
    }).start();

    // The addlistener method is used here, so as to access the animation value as it counts to its stopValue: the toValue
    progress.addListener((e) => setProgressValue(e.value));

    setTimeout(() => {
      progress.removeAllListeners();
    }, 10000);

    setTimeout(() => {
      SetStart(true);
    }, 200);
  }, []);

  const animatedProps = () => ({
    strokeDashoffset: CIRCULAR_CIRCUMFERENCE * progressValue,
  });

  return (
    <View style={styles.container}>
      <Animated.View style={styles.innerContainer}>
        {start ? (
          <View style={styles.circleContainer}>
            <View style={styles.counterContainer}>
              <Text style={styles.counter}>
                {`${Math.floor(-1 * (progressValue * 100 - 100))}%`}
              </Text>
            </View>
            <Svg height={120} width={120} viewBox="0 0 100 100">
              <Circle
                cx={stringifiedWidth}
                cy={stringifiedHeight}
                r={CIRCLE_RADIUS}
                stroke="#5C7E96"
                strokeWidth={10}
                fill="transparent"
                style={{ zIndex: 0 }}
              />
              <AnimatedCircle
                cx={stringifiedWidth}
                cy={stringifiedHeight}
                r={CIRCLE_RADIUS}
                fill="transparent"
                stroke="#D6DF2A"
                strokeWidth={10}
                strokeDasharray={CIRCULAR_CIRCUMFERENCE}
                strokeLinecap="round"
                style={{ zIndex: 0 }}
                {...animatedProps()}
              />
            </Svg>
          </View>
        ) : null}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  circleContainer: {
    position: 'relative',
    zIndex: 0,
  },
  container: {
    alignItems: 'center',
    height: 120,

    justifyContent: 'center',
    width: 120,
  },
  counter: {
    alignSelf: 'center',
    color: '#5C7E96',
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Black',
    fontSize: 25,
    fontWeight: 'bold',
    fontWeight: '500',
  },
  counterContainer: {
    left: '33%',
    position: 'absolute',
    top: '38%',
    zIndex: 1,
  },
  innerContainer: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
});

export default CircularProgressBar;
