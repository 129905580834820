import React, { useEffect, useContext, useState } from 'react';
import { ImageBackground, View, Text, StyleSheet, Image, Pressable } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

import CustomInput from '../../components/CustomInput';
export default function ComunityScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const [name, setName] = useState('');
  const [workingPost, setWorkingPost] = useState('');
  const [comunity, setComunity] = useState(null);

  !state.communityState ? GlobalFunctions.globalGetCommunityData(state.userToken) : '';
  console.log(comunity);
  useEffect(() => {
    setComunity(state.communityState);
  }, [state]);

  let searchIndex = 0;
  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  return (
    <Layout navigation={navigation}>
      <BegekkoBreadCrumb text="Comunidad" />
      <View style={styles.card}>
        <View style={styles.card_title}>
          <Text style={styles.card_title_text}>Qué estás buscando ? </Text>
        </View>

        <View style={styles.card_footer}>
          <CustomInput
            placeholder="Nombre"
            multiline={false}
            isBlank
            onChangeText={(val) => {
              setName(val.toLowerCase());
            }}
          />
          <CustomInput
            placeholder="Puesto de trabajo"
            multiline={false}
            isBlank
            onChangeText={(val) => {
              setWorkingPost(val.toLowerCase());
            }}
          />
        </View>
      </View>

      {comunity
        ? comunity.map((val, index) => {
            let avatar;

            avatar = 'https://begekko.com/images/users/' + val.id + '/avatar/' + val.avatar;

            let jobTitle = '';
            if (val.personal_data.job_title) {
              jobTitle = val.personal_data.job_title.toLowerCase();
            }

            if (
              (val.first_name.toLowerCase().includes(name) || name == '') &&
              (jobTitle.includes(workingPost) || workingPost == '') &&
              searchIndex < 8
            ) {
              searchIndex++;
              return (
                <Pressable
                  style={[styles.card, { marginTop: 15, flexDirection: 'row', padding: 15 }]}
                  key={index + val + generateKey()}
                  onPress={() => {
                    navigation.navigate('GekkoProfileScreen', val);
                  }}>
                  <ImageBackground
                    style={styles.avatar}
                    source={{
                      uri: 'https://begekko.com/images/general/beggeko-airplane-logo.svg',
                    }}>
                    <Image source={{ uri: avatar }} style={styles.image} />
                  </ImageBackground>

                  <View style={{ paddingLeft: 20 }}>
                    <Text style={[styles.text_base, styles.text_big]}>
                      {val.first_name} {val.last_name}
                    </Text>
                    <Text style={[styles.text_base, styles.text_small]}>
                      {val.personal_data.city || ''}
                    </Text>
                  </View>
                </Pressable>
              );
            }
          })
        : null}

      <View style={styles.textInput} />
      <View />
      <View />
    </Layout>
  );
}

const styles = StyleSheet.create(CommonStyles);
