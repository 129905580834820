import React, { useContext, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';
const OfferSentScreen = ({ route, navigation }) => {
  return (
    <Layout navigation={navigation}>
      <View style={styles.title_container}>
        <Text style={styles.title}>Oferta publicada</Text>
        <Text style={styles.after} />
        <View style={styles.check_container}>
          <Text style={styles.check}>&#10004;</Text>
        </View>
      </View>
      <View
        style={{
          marginTop: 'auto',
        }}>
        <CustomButton
          type="SECONDARY"
          onPress={() => {
            navigation.navigate('HotelDashboardScreen', { bye: true });
          }}
          text="< VOLVER"
        />
      </View>
    </Layout>
  );
};

export default OfferSentScreen;

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#D6DF2A',
    height: useViewportDimensions(1, 'vh'),
    marginTop: useViewportDimensions(2, 'vh'),
    width: '66%',
  },
  check: {
    alignSelf: 'center',
    borderBottomLeftRadius: 400,
    borderBottomRightRadius: 400,
    borderColor: 'green',
    borderStyle: 'solid',
    borderTopLeftRadius: 400,
    borderTopRightRadius: 400,
    borderWidth: 3,
    color: 'green',
    fontSize: useViewportDimensions(10, 'vh'),
    height: useViewportDimensions(15, 'vh'),
    textAlign: 'center',
    textAlignVertical: 'center',
    width: useViewportDimensions(15, 'vh'),
  },
  check_container: {
    marginHorizontal: 'auto',
    marginTop: useViewportDimensions(15, 'vh'),
  },
  title: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Black',
    fontSize: 25,
    fontWeight: '500',
    lineHeigh: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  title_container: {
    textAlignVertical: 'center',
  },
});
