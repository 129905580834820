import { View, Text, StyleSheet } from 'react-native';

export default function MediumHeading({ text }) {
  return (
    <View>
      <Text style={styles.title}>{text}</Text>
      <Text style={styles.after} />
    </View>
  );
}

const styles = StyleSheet.create({
  after: {
    backgroundColor: '#D6DF2A',
    height: 1,
    marginBottom: 15,
    marginTop: 5,
    width: '15%',
  },
  title: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Medium',
    fontSize: 18,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
});
