import React, { useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ScrollView, SafeAreaView, Image } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

export default function GekkoProfileScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const profile = state.personalData;

  // Log the parsed profile data to the console for debugging purposes
  console.log(route.params, 'Gekko profile');

  // Destructure the properties of the profile object for easier access
  let {
    id,
    avatar,
    created_at,
    birthday,
    city,
    country,
    first_name,
    last_name,
    email,
    freelance,
    linkedin_id,
    personal_data,
  } = route.params;
  avatar = 'https://begekko.com/images/users/' + id + '/avatar/' + avatar;
  let featured_image = personal_data.featured_image.replace(/\.png$/, '-sm.png');
  featured_image = 'https://begekko.com/images/profile/' + featured_image;

  return (
    <Layout navigation={navigation}>
      <BegekkoBreadCrumb text="Volver" />
      <View style={styles.card}>
        <View style={styles.card_body}>
          <View style={styles.container}>
            <Text style={styles.card_text}>
              <Text style={styles.text1}>Nombre:</Text> {first_name}
            </Text>
            <Text style={styles.card_text}>
              <Text style={styles.text1}>Apellido:</Text> {last_name}
            </Text>
            <Image source={{ uri: avatar }} style={styles.avatar} />
            <View style={styles.info}>
              {/* Display the profile data */}

              <Text style={styles.card_text}>
                <Text style={styles.text1}>Fecha de nacimiento: </Text>
                {personal_data.birthday}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Sitio: </Text> {city}, {country}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Joined: </Text>
                {created_at.substring(0, 10)}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Genero: </Text>
                {personal_data.gender}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Puesto del trabajo:</Text>
              </Text>
              <Text style={styles.text1}>Correo electronico: </Text>
              <Text style={styles.card_text}>{email}</Text>
            </View>
          </View>
        </View>
        <Image source={{ uri: featured_image }} style={styles.featuredImage} />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    avatar: {
      borderRadius: 25,
      height: 150,
      marginBottom: 10,
      marginTop: 10,
      width: 150,
    },
    card_body: {
      padding: 20,
    },
    card_text: {
      fontFamily: 'asap-regular',
      fontSize: 15,
      marginTop: 10,
    },
    container: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    },
    featuredImage: {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: 200,
      margin: 'auto',
      marginBottom: 10,
      resizeMode: 'cover',
      width: '90%',
    },
    info: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20,
    },
    profilePicture: {
      borderRadius: 75,
      height: 150,
      marginBottom: 20,
      width: 150,
    },
    text1: {
      fontFamily: 'asap-regular',
      fontSize: 15,
      fontWeight: 'bold',
      marginTop: 10,
    },
  },
});
