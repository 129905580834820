import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import LogoColor from 'src/components/svgs/LogoFullDark';
import useViewportDimensions from 'src/integrations/useViewportDimensions';

export default function LogoComponent() {
  return (
    <View style={styles.logo_container}>
      <LogoColor />
    </View>
  );
}

const styles = StyleSheet.create({
  logo_container: {
    backgroundColor: 'white',
    borderColor: '#ff0',
    borderRadius: 20,
    borderWidth: 2,
    height: useViewportDimensions(25, 'vh'),
    paddingHorizontal: useViewportDimensions(5, 'vw'),
    paddingVertical: useViewportDimensions(5, 'vh'),
  },
});
