import * as ImagePicker from 'expo-image-picker';
import React, { useState } from 'react';
import { Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import CustomButton from 'src/components/CustomButton';
export default function ImagePickerExample({ onChange, initialValue = null }) {
  const [image, setImage] = useState(initialValue);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      height: 1600,
      width: 1200,
    });
    //const response = await fetch(result.uri); todo: image uri to blob
    //const blob = await response.blob();

    if (!result.canceled) {
      setImage(result.assets[0].uri);
      onChange(result.assets[0].uri);
    }
  };

  const openCamera = async () => {
    // Ask the user for the permission to access the camera

    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your camera!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync();

    if (!result.canceled) {
      setImage(result.assets[0].uri);
      onChange(result.assets[0].uri);
    }
  };

  return (
    <>
      <TouchableOpacity style={styles.picker} onPress={pickImage}>
        {!image ? (
          <Text style={{ marginVertical: 'auto' }}>Añadir foto</Text>
        ) : (
          <Image source={{ uri: image }} style={{ width: '100%', height: '100%' }} />
        )}
      </TouchableOpacity>

      <CustomButton type="PRIMARY" text="Pick foto con camara>" onPress={openCamera} />
    </>
  );
}
const styles = StyleSheet.create({
  picker: {
    alignItems: 'center',
    backgroundColor: '#f6f7fa',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'dashed',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 2,
    height: 300,
    justifyContent: 'center',
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textAlign: 'center',
    width: '100%',
  },
  title: {
    border: '0',
    margin: '0',
    padding: '0',
  },
});
