import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import CommonStyles from 'src/styles/CommonStyles';

/* Components */
import RenderedStars from './RenderedStars';

export default function HotelListItem({ onPress, name, address, price, stars, mainImage }) {
  return (
    <TouchableOpacity style={styles.hotel_card} onPress={onPress}>
      <Image
        style={styles.hotel_list_image}
        source={{
          uri: mainImage,
        }}
        resizeMode="cover"
      />
      <View style={styles.hotel_info}>
        <Text style={styles.hotel_title}>{name}</Text>
        <RenderedStars amount={stars} />
        <Text style={styles.hotel_address}>{address.includes('null') ? '' : address}</Text>
        <Text style={styles.hotel_price}>{price}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create(CommonStyles);
