import React, { useEffect, useContext, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

import SelectField from '../../components/SelectField';
import StarComponent from '../../components/StarComponent';
import StaticComponentScreen from '../../components/StatisticComponent';
import useViewportDimensions from '../../integrations/useViewportDimensions';

export default function InformesScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const [scrapperData, setScrapperData] = useState(undefined);
  const [selectedHotel, setHotelSelected] = useState(undefined);
  const [puntuacionGlobal, setPuntuacionGlobal] = useState(5);

  useEffect(() => {
    if (state.hasOwnProperty('hotelState')) {
      setScrapperData(state.hotelState.data);
      setHotelSelected(state.hotelState.data[0].name);
    }
    console.log('state', state);
  }, [state]);

  ///set up puntuacion global

  useEffect(() => {
    const data = scrapperData?.filter((val) => {
      if (val.name === selectedHotel) {
        return val;
      }
    });

    if (data) {
      setPuntuacionGlobal(data[0].rating + '');

      console.log(data[0].rating, 'puntuacion global');
    }
  }, [selectedHotel]);

  return (
    <Layout navigation={navigation}>
      <BegekkoBreadCrumb text="Informes" />
      <View>
        {scrapperData ? (
          <SelectField
            placeHolder="HOTELS LIST"
            label={scrapperData.map((val) => {
              return val.name;
            })}
            onValueChanged={(item) => {
              setHotelSelected(item);
            }}
          />
        ) : null}

        <Text style={styles.title}>Puntuación general</Text>
        <StarComponent style={styles.stars} value={puntuacionGlobal} />
        <Text style={styles.punt}>{puntuacionGlobal}</Text>
        <View style={{ margin: 25 }} />
        <View style={styles.card}>
          <View style={styles.card_title}>
            <Text style={styles.card_title_text}>DEPARTAMENTOS</Text>
          </View>
          <View style={[styles.card_footer, { alignSelf: 'stretch' }]}>
            <StaticComponentScreen bw={80} exp={95} dsm={75} fb={85} />
          </View>
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    after: {
      alignSelf: 'center',
      backgroundColor: '#D6DF2A',
      height: 1,
      marginTop: 10,
      width: '88%',
    },
    punt: {
      color: '#5C7E96',
      fontFamily: 'EchoesSans-Black',
      fontSize: 45,
      fontWeight: '500',
      lineHeigh: 1.2,
      textAlign: 'center',
      textTransform: 'uppercase',
      textTransform: 'uppercase',
    },
    stars: { margin: 0 },
    text: {
      fontFamily: 'asap-regular',
      fontSize: 20,
      fontWeight: '500',
      marginBottom: 20,
      marginTop: 'auto',
    },
    title: {
      color: '#5C7E96',
      fontFamily: 'EchoesSans-Black',
      fontSize: 25,
      fontWeight: '500',
      lineHeigh: 1.2,
      marginTop: 10,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
});
