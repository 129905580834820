import { View, Text, Image, StyleSheet } from 'react-native';
import CommonStyles from 'src/styles/CommonStyles';

export default function HotelDetailsSlider({ mainImage }) {
  return (
    <Image
      style={styles.hotel_slider_image}
      source={{
        uri: mainImage,
      }}
      resizeMode="cover"
    />
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    hotel_slider_image: {
      marginVertical: 25,
      width: '100%',
      height: 200,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
});
