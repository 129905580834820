import { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Text, View, StyleSheet, Picker } from 'react-native';
import { CheckBox } from 'react-native-elements';
import GlobalContext from 'src/GlobalContext';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';

import CommonStyles from '../../styles/CommonStyles';
import CustomButton from '../CustomButton';
import CustomInput from '../CustomInput';
export default function CreateOfferForm({ onSubmit, navigation }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      how_to_book: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmitForm = (data) => {
    onSubmit(data);
  };

  return (
    <View style={{ padding: 40 }}>
      <BegekkoBreadCrumb text="Volver" />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Picker
              style={styles.picker}
              onBlur={onBlur}
              onValueChange={onChange}
              selectedValue={value}>
              <Picker.Item label="Cómo hace la reserva" value="select" />

              <Picker.Item label="Soy Hotelero" value="hotelier" />
              <Picker.Item label="Soy Profesional" value="gekko" />
            </Picker>
            {errors?.role?.type === 'required' && (
              <Text style={{ color: 'red' }}>Selecciona tu rol</Text>
            )}
          </>
        )}
        name="how_to_book"
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Picker
              style={styles.picker}
              onBlur={onBlur}
              onValueChange={onChange}
              selectedValue={value}>
              <Picker.Item label="Nivel profesional" value="select" />

              <Picker.Item label="Soy Hotelero" value="hotelier" />
              <Picker.Item label="Soy Profesional" value="gekko" />
            </Picker>
            {errors?.role?.type === 'required' && (
              <Text style={{ color: 'red' }}>Selecciona tu rol</Text>
            )}
          </>
        )}
        name="how_to_book"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput
            placeholder="Mensaje al hotelero"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            multiline
          />
        )}
        name="message"
      />
      <View style={styles.validation}>
        {errors?.last_name?.type === 'minLength' && (
          <Text style={{ color: 'red' }}>Al menos 4 caracteres</Text>
        )}
        {errors?.last_name?.type === 'required' && <Text style={{ color: 'red' }}>Complete</Text>}
      </View>
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <CheckBox
              title="My Checkbox"
              checkedIcon={
                <View
                  style={{ backgroundColor: '#D2D745', width: 20, height: 20, borderRadius: 6 }}
                />
              }
              uncheckedIcon={
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: '#637D94',
                    width: 20,
                    height: 20,
                    borderRadius: 6,
                  }}
                />
              }
              containerStyle={{ backgroundColor: 'transparent', borderWidth: 0 }}
              textStyle={[styles.text_base]}
              checked={value}
              onPress={() => onChange(!value)}
            />
          </>
        )}
        name="departments"
      />
      <View style={styles.validation}>
        {errors?.last_name?.type === 'minLength' && (
          <Text style={{ color: 'red' }}>Al menos 4 caracteres</Text>
        )}
        {errors?.last_name?.type === 'required' && <Text style={{ color: 'red' }}>Complete</Text>}
      </View>

      <CustomButton type="PRIMARY" text="CREAR OFERTA" onPress={handleSubmit(onSubmitForm)} />
    </View>
  );
}
const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    button_container: {
      marginTop: 'auto',
    },
    gap: { width: useViewportDimensions(4, 'vw') },
    gekkoLine: {
      margin: 25,
      width: '88%',
      height: 1,
      backgroundColor: '#adadad',
      alignSelf: 'center',
    },
    navigation: { paddingTop: 50 },

    picker: {
      fontFamily: 'asap-regular',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      lineHeight: 18,
      color: '#8BA5BD',
      backgroundColor: '#E3EDF4',
      borderWidth: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      marginTop: 16,
      placeholderTextColor: '#8BA5BD',
      textAlignVertical: 'top',
      width: '100%',
    },
    text: {
      fontFamily: 'asap-regular',
      fontSize: 20,
      fontWeight: '600',
      paddingBottom: useViewportDimensions(1, 'vh'),
      paddingTop: useViewportDimensions(5, 'vh'),
    },
    validation: {
      paddingTop: 5,
    },
  },
});
