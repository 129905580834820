import { View, StyleSheet } from 'react-native';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import CommonStyles from 'src/styles/CommonStyles';

import BinaryQuestion from './BinaryQuestion';
import ImagePicker from './ImagePicker';
import StarsComponent from './StarsQuestion';
import CustomInput from '../../../components/CustomInput';

const Question = ({ type, large, onChange, value }) => {
  return (
    <View>
      {type === 'question' ? (
        <View styles={{ paddingVertical: useViewportDimensions(6, 'vh') }}>
          <CustomInput
            placeholder="Introduzca su respuesta..."
            multiline={large}
            onChangeText={onChange}
            value={value}
            isBlank
          />
        </View>
      ) : null}
      {type === 'binary' ? <BinaryQuestion onChange={onChange} initialValue={value} /> : null}
      {type === 'star' ? <StarsComponent onChange={onChange} initialValue={value} /> : null}
      {type === 'image' ? <ImagePicker onChange={onChange} initialValue={value} /> : null}
    </View>
  );
};

export default Question;

const styles = StyleSheet.create(CommonStyles);
