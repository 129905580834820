import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useContext } from 'react';
import { View, Image, Text, Pressable, StyleSheet } from 'react-native';
import BackArrowSVG from 'src/components/svgs/BackArrowSVG';

export default function BegekkoBreadCrumb({ text }) {
  const navigation = useNavigation();
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 50,
        marginTop: 15,
      }}>
      <Pressable onPress={() => navigation.goBack()}>
        <BackArrowSVG />
      </Pressable>
      <Text style={styles.navigation_title}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  navigation_title: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Medium',
    fontSize: 18,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
});
