import React, { useRef } from 'react';
import { Animated, View, StyleSheet, TouchableOpacity, Text, Dimensions } from 'react-native';

export default function NavBarMenuButton() {
  const animValue = useRef(new Animated.Value(0)).current;

  const showMenu = () => {
    Animated.timing(animValue, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const hideMenu = () => {
    Animated.timing(animValue, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const menuStyle = {
    opacity: animValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
    transform: [
      {
        translateY: animValue.interpolate({
          inputRange: [0, 1],
          outputRange: [-100, 0],
        }),
      },
    ],
    width: Dimensions.get('window').width,
  };

  return <View style={styles.container} />;
}

const styles = StyleSheet.create({
  container: {},
  menu: {},
});
