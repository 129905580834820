import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function BeGekkoIconSVG(props) {
  return (
    <Svg viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M23 11.6H11.5v5.8h5.75c0 3.203-2.574 5.8-5.75 5.8-3.175 0-5.75-2.596-5.75-5.8 0-3.204 2.575-5.8 5.75-5.8V5.8c-2.095 0-4.058.568-5.75 1.556V0H0v17.528h.003C.072 23.875 5.192 29 11.5 29 17.851 29 23 23.806 23 17.4v-5.8z"
        fill="#E3EDF4"
      />
    </Svg>
  );
}

export default BeGekkoIconSVG;
