import React, { useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';

export default function OfferScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);

  useEffect(() => {
    if (state.pendingForms === null) {
      GlobalFunctions.globalGetHotelsData(state.userToken);
    }
  }, [route]);

  return (
    <Layout navigation={navigation}>
      <View>
        <View style={styles.card_footer}>
          <CustomButton
            style={[{ textAlign: 'left' }, styles.border_bottom]}
            type="THIRD"
            text="NUEVA OFERTA"
            onPress={() => {
              navigation.navigate('NewOfferScreen');
            }}
          />
        </View>
        <View style={styles.card_footer}>
          <CustomButton
            style={[{ textAlign: 'left' }, styles.border_bottom]}
            type="THIRD"
            text="LISTA DE INFORMES"
            onPress={() => {
              navigation.navigate('InformesScreen');
            }}
          />
        </View>
        <View />
      </View>

      <View style={{ marginTop: 'auto' }}>
        <CustomButton
          type="PRIMARY"
          text="ATRAS"
          onPress={() => {
            navigation.navigate('HotelDashboardScreen');
          }}
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#D6DF2A',
    height: useViewportDimensions(1, 'vh'),
    marginTop: useViewportDimensions(2, 'vh'),
    width: '66%',
  },
  card: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    marginVertical: 'auto',
  },
  card_body: {
    padding: 20,
  },
  card_footer: {
    borderColor: '#ececec',
    borderTopWidth: 15,
    color: 'white',
    textAlign: 'left',
  },
  card_text: {
    color: 'white',
    fontFamily: 'asap-regular',
    fontSize: 25,
  },
  card_title: {
    color: 'white',
    fontSize: 25,
    marginBottom: 20,
  },
  text: {
    fontFamily: 'asap-regular',
    fontSize: 20,
    fontWeight: '500',
    marginBottom: 20,
    marginTop: 'auto',
  },
  title: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Black',
    fontSize: 25,
    fontWeight: '500',
    lineHeigh: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase',
    textTransform: 'uppercase',
  },
});
