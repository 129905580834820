import Loader from 'assets/loading.svg';
import React from 'react';
import { Text, Image, StyleSheet, Pressable } from 'react-native';

const CustomButton = ({ onPress, text, type = 'PRIMARY', style, loading }) => {
  return (
    <Pressable
      disabled={loading}
      onPress={onPress}
      style={[styles.container, styles[`container_${type}`], style]}>
      {loading ? (
        <Image
          source={Loader}
          style={{
            width: 100,
            height: 20,
          }}
          resizeMode="contain"
        />
      ) : (
        <Text style={[styles.text, styles[`text_${type}`]]}>{text}</Text>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingBottom: 9,
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 9,
  },

  container_LOGIN: {
    borderBottomColor: 'green',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 0,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
  },

  container_PRIMARY: {
    backgroundColor: '#D6DF2A',
    borderColor: '#D6DF2A',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 2,
  },

  container_SECONDARY: {
    backgroundColor: '#5C7E96',
    borderColor: '#5C7E96',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 2,
  },
  container_THIRD: {
    alignSelf: 'stretch',
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderTopWidth: 2,
  },
  text: {
    fontFamily: 'EchoesSans-Thin',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    textDecorationColor: 'black',
    textDecorationLine: 'none',
    textDecorationStyle: 'solid',
  },

  text_PRIMARY: {
    color: '#527186',
  },
  text_SECONDARY: {
    color: 'white',
  },
  text_THIRD: {
    color: '#527186',
    fontSize: 18,
  },
});

export default CustomButton;
