import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import CircularProgressBar from './DiagramComponent';
const StaticComponentScreen = ({ bw, exp, dsm, fb }) => {
  return (
    <>
      <View style={styles.row}>
        <View style={styles.itemTextUp}>
          <Text style={styles.textUp}>BeWelcome</Text>
        </View>
        <View style={styles.itemTextUp}>
          <Text style={styles.textUp}>Experience</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.item}>
          <CircularProgressBar progressBar={bw} />
        </View>
        <View style={styles.item}>
          <CircularProgressBar progressBar={exp} />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.item}>
          <CircularProgressBar progressBar={dsm} />
        </View>
        <View style={styles.item}>
          <CircularProgressBar progressBar={fb} />
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.itemTextUp}>
          <Text style={styles.textUp}>F&B</Text>
        </View>
        <View style={styles.itemTextUp}>
          <Text style={styles.textUp}>Digital Status & Mkt</Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  item: {},
  itemTextDown: {},
  itemTextUp: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    marginVertical: 15,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 15,
    marginTop: 20,
  },
  textUp: {
    alignSelf: 'center',
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Black',
  },
});

export default StaticComponentScreen;
