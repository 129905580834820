import { Picker } from '@react-native-picker/picker';
import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
const SelectField = ({ placeHolder, onValueChanged, label }) => {
  const [selectedValue, setSelectedValue] = useState('');

  const [label1, setSelectedlabel] = useState(label);

  console.log('label', label1);
  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  return (
    <View style={styles.block}>
      <Text style={styles.input}>{placeHolder}</Text>
      <Picker
        style={styles.input}
        selectedValue={selectedValue}
        onValueChange={(itemValue, itemIndex) => {
          onValueChanged(itemValue);
          setSelectedValue(itemValue);
        }}>
        {label
          ? label.map((val, index) => {
              return <Picker.Item key={index + val + generateKey()} label={val} value={val} />;
            })
          : 'null'}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  block: { marginTop: 15 },
  container: { marginLeft: 0, marginRight: 0, width: '100%' },
  input: {
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    color: '#333',
    fontFamily: 'asap-regular',
    fontSize: 20,
    fontWeight: '100',
    paddingBottom: 17,
    paddingLeft: 17,
    paddingRight: 17,
    paddingTop: 17,
    placeholderTextColor: '#989898',
    textAlignVertical: 'top',
    width: '100%',
  },
});

export default SelectField;
