import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
const NavbarButton = ({ routeName, actualRoute, text, togglePanel }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => {
        togglePanel();
        navigation.navigate(routeName, {
          key: Date.now().toString(),
        });
      }}
      style={styles.container}>
      <Text style={styles.button}>{text}</Text>
      {actualRoute !== routeName ? null : <Text style={styles.after} />}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#5c7e96',
    height: 1,
    width: '23%',
  },
  button: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Medium',
    fontSize: 16,
    fontWeight: '500',
    lineHeigh: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    alignSelf: 'stretch',
    backgroundColor: '#FFFFFF',
    borderColor: '#ECECEC',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 2,
    marginBottom: 15,
    marginHorizontal: 20,
    marginTop: 0,
    padding: 9,
  },
});

export default NavbarButton;
