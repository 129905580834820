import React, { useEffect, useContext } from 'react';
import { View, Text, Pressable, StyleSheet, Image, ImageBackground } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import BigHeading from 'src/components/headings/BigHeading';
import MediumHeading from 'src/components/headings/MediumHeading';
import BeGekkoIconSVG from 'src/components/svgs/BeGekkoIconSVG';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

export default function HotelHomeScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);

  useEffect(() => {
    if (state.pendingForms === null) {
      GlobalFunctions.globalGetHotelsData(state.userToken);
    }
  }, [route]);

  return (
    <Layout navigation={navigation}>
      <BigHeading text={'Bienvenido, ' + state.userData.first_name} />

      <View style={{ height: 290 }}>
        <View style={{ flex: 1, flexDirection: 'row', width: '100%', marginTop: 6 }}>
          <View
            style={[
              styles.card,
              styles.background_blue,
              {
                flex: 1,
                padding: 15,
                flexDirection: 'row',
                marginRight: 6,
                marginBottom: 6,
                justifyContent: 'space-around',
                alignItems: 'center',
              },
            ]}>
            <ImageBackground
              style={{ height: 40, width: 40, borderRadius: 100 }}
              source={{
                uri: 'https://begekko.com/images/general/beggeko-airplane-logo.svg',
              }}
            />
            <View>
              <Text style={[styles.text_base, styles.text_white, { fontSize: 20 }]}>Mariano</Text>
              <Text style={[styles.text_base, styles.text_white, { fontSize: 15 }]}>Martínez</Text>
            </View>
          </View>
          <View
            style={[
              styles.card,
              styles.background_blue,
              { flex: 1, padding: 15, marginLeft: 6, marginBottom: 6, justifyContent: 'center' },
            ]}>
            <View
              style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
              <Text style={[styles.big_title, styles.text_white]}>5</Text>
              <BeGekkoIconSVG width={35} height={35} style={{ marginBottom: 6 }} />
            </View>

            <Text
              style={[
                styles.text_base,
                styles.text_white,
                styles.text_muted,
                { fontSize: 15, textAlign: 'center' },
              ]}>
              Valoración BG
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', width: '100%', marginTop: 6 }}>
          <View
            style={[
              styles.card,
              styles.background_yellow,
              { flex: 1, marginRight: 6, marginBottom: 6 },
            ]}>
            <View>
              <View style={{ padding: 15 }}>
                <Text style={[styles.text_base, styles.big_title]}>0</Text>
                <Text style={[styles.text_normal, styles.text_base]}>Ofertas activas</Text>
              </View>

              <Pressable
                onPress={() => navigation.navigate('')}
                style={{ borderTopWidth: 2, borderTopColor: '#637D94', padding: 10 }}>
                <Text style={styles.text_base}>Ver todas las ofertas</Text>
              </Pressable>
            </View>
          </View>
          <View
            style={[
              styles.card,
              styles.background_orange,
              { flex: 1, marginLeft: 6, marginBottom: 6 },
            ]}>
            <View style={{ padding: 15 }}>
              <Text style={[styles.text_base, styles.big_title]}>0</Text>
              <Text style={[styles.text_normal, styles.text_base]}>Peticiones pendientes</Text>
            </View>
            <Pressable
              onPress={() => navigation.navigate('')}
              style={{ borderTopWidth: 2, borderTopColor: '#637D94', padding: 10 }}>
              <Text style={styles.text_base}>Ver todas las peticiones</Text>
            </Pressable>
          </View>
        </View>
      </View>

      <View style={[styles.card, styles.profile]}>
        <Image
          source={{
            uri: `https://begekko.com/images/profile/profile_picture_placeholder.png`,
          }}
          style={styles.image}
        />
        <View style={{ paddingLeft: 20, justifyContent: 'center' }}>
          <Text style={[styles.text_base, styles.text_big]}>
            {state.userData.first_name} {state.userData.last_name}
          </Text>
          <Text style={[styles.text_base, styles.text_small]}>{state.personalData.city}</Text>
        </View>
      </View>
      <View style={styles.card}>
        <View style={styles.card_title}>
          <Text style={styles.card_title_text}>Peticiones</Text>
          <View style={styles.card_footer} />
        </View>
      </View>
      <View style={styles.card}>
        <View style={styles.card_title}>
          <Text style={styles.card_title_text}>Ofertas</Text>
          <View style={styles.card_footer} />
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    after: {
      alignSelf: 'center',
      backgroundColor: '#D6DF2A',
      height: useViewportDimensions(1, 'vh'),
      marginTop: useViewportDimensions(2, 'vh'),
      width: '66%',
    },
    text: {
      fontFamily: 'asap-regular',
      fontSize: 20,
      fontWeight: '500',
      marginBottom: 20,
      marginTop: 'auto',
    },
    big_title: {
      fontSize: 40,
    },
    title: {
      color: '#5C7E96',
      fontFamily: 'EchoesSans-Black',
      fontSize: 25,
      fontWeight: '500',
      lineHeigh: 1.2,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
});
