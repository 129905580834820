import StarDisabled from 'assets/icon-disabled.png';
import StarEnabled from 'assets/icon.png';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import useViewportDimensions from 'src/integrations/useViewportDimensions';

const Star = ({ disabled }) => (
  <View>
    <Image
      source={disabled ? StarDisabled : StarEnabled}
      style={{
        width: useViewportDimensions(15, 'vw'),
        height: useViewportDimensions(15, 'vw'),
        alignSelf: 'center',
      }}
      resizeMode="contain"
    />
  </View>
);

const StarComponent = ({ value }) => {
  const [selection, setSelection] = useState(value);

  console.log(value, 'value', selection);
  useEffect(
    (val) => {
      setSelection(value);
    },
    [value]
  );

  return (
    <View style={styles.stars}>
      <Star disabled={!(selection >= 1)} />
      <Star disabled={!(selection >= 2)} />
      <Star disabled={!(selection >= 3)} />
      <Star disabled={!(selection >= 4)} />
      <Star disabled={selection <= 4} />
    </View>
  );
};

export default StarComponent;

const styles = StyleSheet.create({
  stars: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: useViewportDimensions(6, 'vh'),
    width: '100%',
  },
});
