import { StyleSheet, View, Text, TouchableOpacity, Animated } from 'react-native';
export default function BlueInput({ value, onChangeText, placeholder }) {
  return (
    <TextInput
      value={userName}
      onChangeText={onChangeText}
      placeholder={placeholder}
      style={styles.input}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: '#E3EDF4',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#8BA5BD',
    fontFamily: 'Asap',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
  },
});
