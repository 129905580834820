import { useNavigation } from '@react-navigation/native';
import Loader from 'assets/loading.svg';
import { useState, useEffect, useContext } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import BigHeading from 'src/components/headings/BigHeading';
import { getHotelList } from 'src/integrations/API';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

/* Web API */

/* Components */
import HotelListComponent from './components/HotelListComponent';

export default function HotelListScreen({ route, navigation, ...props }) {
  const [isLoading, setIsLoading] = useState(true);

  const [hotelList, setHotelList] = useState([]);

  useEffect(() => {
    const loadHotelList = async () => {
      setIsLoading(true);
      const hotels = await getHotelList();
      if (hotels) {
        setHotelList(hotels);
        setIsLoading(false);
      }
    };
    loadHotelList();
    const unsubscribe = navigation.addListener('focus', () => {
      loadHotelList();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <Layout navigation={navigation}>
      <BigHeading text="Hoteles para Gekkos" />
      {isLoading ? (
        <Image source={Loader} style={{ flex: 1 }} resizeMode="contain" />
      ) : (
        <View style={styles.hotel_list_container}>
          <HotelListComponent arrayHotels={hotelList} />
        </View>
      )}
    </Layout>
  );
}
const styles = StyleSheet.create(CommonStyles);
