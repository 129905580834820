import { useRoute } from '@react-navigation/native';
import React, { useEffect, useContext, useState } from 'react';
import { View, Text, Image, Pressable, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import LogoColor from 'src/components/svgs/LogoColor';
import MenuSVG from 'src/components/svgs/MenuSVG.js';
import NavBarMenuButton from 'src/layout/component/NavBarMenuButton';
import NavbarButton from 'src/layout/component/NavbarButton';
const NavBar = ({ isDisabled, isEmpty, navigation, togglePanel }) => {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const route = useRoute();
  console.log(togglePanel);
  return isDisabled ? null : (
    <>
      <NavBarMenuButton />
      <View style={styles.root}>
        <LogoColor style={{ width: 160, marginHorizontal: 20 }} />

        {isEmpty ? null : (
          <>
            <View
              style={{
                flexDirection: 'row-reverse',
                flex: 1,
                paddingRight: 30,
                justifyContent: 'right',
                alignItems: 'center',
              }}>
              <View>
                <Pressable onPress={togglePanel} style={styles.closeButton}>
                  <MenuSVG />
                </Pressable>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};
export default NavBar;

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#f6f7fa',
    borderBottomWidth: 2,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    flexDirection: 'row',
    height: 70,

    justifyContent: 'space-between',
    marginVertical: 'auto',
    position: 'relative',
  },
});
