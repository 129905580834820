import React from 'react';
import { View, StyleSheet } from 'react-native';

const ProgressBar = ({ current, max, min }) => {
  const percentage = ((current - min) * 100) / (max - min); /////////
  return (
    <>
      <View style={styles.container}>
        <View style={[styles.progress, { width: `${percentage}%` }]} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#5C7E96',
    borderRadius: 10,
    height: 20,
    justifyContent: 'center',
    marginVertical: 15,
    overflow: 'hidden',
  },
  progress: {
    backgroundColor: '#D6DF2A',
    borderRadius: 10,
    height: 20,
  },
});

export default ProgressBar;
