import { View, Text, StyleSheet } from 'react-native';
import StarSVG from 'src/components/svgs/StarSVG';
export default function RenderedStars({ amount }) {
  const renderedStars = [...Array(amount)].map((_, i) => <StarSVG key={i} />);
  return <View style={styles.star_wrapper}>{renderedStars}</View>;
}

const styles = StyleSheet.create({
  star_icon: {
    color: '#ffb400',
    fontFamily: 'icomoon',
    fontSize: 10,
  },
  star_wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
