import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import SignInForm from 'src/components/forms/SignInForm';
import LogoColor from 'src/components/svgs/LogoColor';
import Layout from 'src/layout/Layout';
import LogoComponent from 'src/layout/component/LogoComponent';

import CustomButton from '../components/CustomButton';
import BigHeading from '../components/headings/BigHeading';
export default function SignInScreen({ navigation }) {
  const { GlobalFunctions } = React.useContext(GlobalContext);

  return (
    <Layout NavBarDisabled>
      <View style={{ height: '100%', justifyContent: 'center' }}>
        <LogoColor style={{ width: 200, height: 40, alignSelf: 'center' }} />
        <SignInForm onSubmit={GlobalFunctions.signIn} navigation={navigation} />
      </View>
    </Layout>
  );
}
