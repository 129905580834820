import { useNavigation } from '@react-navigation/native';
import Loader from 'assets/loading.svg';
import { useState, useEffect, useContext } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import MediumHeading from 'src/components/headings/MediumHeading';
import { getRequestsList } from 'src/integrations/API';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

/* Web API */

/* Components */
import RequestCard from './RequestCard';

export default function GekkoRequestsList({ navigation, ...props }) {
  const { state } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);

  const [hotelRequests, setHotelRequests] = useState([]);

  useEffect(() => {
    const loadHotelRequests = async () => {
      setIsLoading(true);
      const requests = await getRequestsList({ token: state.userToken });
      if (requests) {
        setHotelRequests(requests.reverse());
        setIsLoading(false);
      }
    };
    loadHotelRequests();
    const unsubscribe = navigation.addListener('focus', () => {
      loadHotelRequests();
    });

    return unsubscribe;
  }, [navigation]);

  if (isLoading) {
    return (
      <View style={{ height: '100%', width: '100%' }}>
        <Image source={Loader} style={{ flex: 1 }} resizeMode="contain" />
      </View>
    );
  }

  const approvedRequests = hotelRequests.filter((request) => request.status === 'approved');
  return (
    <>
      <View style={styles.card}>
        <View style={styles.card_title}>
          <Text style={styles.card_title_text}>Valoraciones pendientes: </Text>
          <Text style={styles.card_title_text}>{approvedRequests.length || 0}</Text>
        </View>
        {approvedRequests.length !== 0 &&
          approvedRequests.map((request, i) => (
            <CustomButton
              key={request.id}
              type="THIRD"
              text={request.name}
              onPress={async () => {
                navigation.navigate('GekkoRequestDetailsScreen', {
                  requestId: request.id,
                  hotelName: request.name,
                });
              }}
            />
          ))}
      </View>

      <View style={{ marginTop: 40 }}>
        <MediumHeading text="Tus peticiones:" />
      </View>
      {hotelRequests.map((request, i) => (
        <RequestCard
          key={request.id}
          hotelName={request.name}
          status={request.status}
          checkin={request.checkin}
          checkout={request.checkout}
          requested_by_role={request.requested_by_role}
        />
      ))}
    </>
  );
}

const styles = StyleSheet.create(CommonStyles);
