import 'react-native-gesture-handler';
import { useFonts } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { GlobalContextWrapper } from 'src/GlobalContext';
import Routes from 'src/Routes';

function App() {
  const [fontsLoaded] = useFonts({
    'asap-regular': require('./assets/fonts/asap-regular.otf'),
    'asap-bold': require('./assets/fonts/asap-bold.otf'),
    'asap-semibold': require('./assets/fonts/asap-semibold.otf'),
    'EchoesSans-Black': require('./assets/fonts/EchoesSans-Black.otf'),
    'EchoesSans-Medium': require('./assets/fonts/EchoesSans-Medium.otf'),
    'EchoesSans-Thin': require('./assets/fonts/EchoesSans-Thin.otf'),
    'Inter-Black': require('./assets/fonts/Inter-Black.otf'),
    'Myriad-Pro-Regular': require('./assets/fonts/Myriad-Pro-Regular.otf'),
    icomoon: require('./assets/fonts/icomoon.otf'),
  });

  if (fontsLoaded) {
    return (
      <GlobalContextWrapper>
        <Routes />
      </GlobalContextWrapper>
    );
  }
}

export default App;
