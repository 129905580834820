import StarDisabled from 'assets/icon-disabled.png';
import StarEnabled from 'assets/icon.png';
import React, { useState } from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import useViewportDimensions from 'src/integrations/useViewportDimensions';

const Star = ({ disabled, onClick }) => (
  <TouchableOpacity onPress={onClick}>
    <Image
      source={disabled ? StarDisabled : StarEnabled}
      style={{
        width: useViewportDimensions(15, 'vw'),
        height: useViewportDimensions(15, 'vw'),
        alignSelf: 'center',
      }}
      resizeMode="contain"
    />
  </TouchableOpacity>
);

const StarsComponent = ({ onChange, initialValue }) => {
  const [selection, setSelection] = useState(initialValue ? 0 : initialValue);

  console.log('my initial is', initialValue);
  return (
    <TouchableOpacity style={styles.stars}>
      <Star
        disabled={!(initialValue >= 1)}
        onClick={() => {
          setSelection(1);
          onChange(1);
        }}
      />
      <Star
        disabled={!(initialValue >= 2)}
        onClick={() => {
          setSelection(2);
          onChange(2);
        }}
      />
      <Star
        disabled={!(initialValue >= 3)}
        onClick={() => {
          setSelection(3);
          onChange(3);
        }}
      />
      <Star
        disabled={!(initialValue >= 4)}
        onClick={() => {
          setSelection(4);
          onChange(4);
        }}
      />
      <Star
        disabled={!(initialValue >= 5)}
        onClick={() => {
          setSelection(5);
          onChange(5);
        }}
      />
    </TouchableOpacity>
  );
};

export default StarsComponent;

const styles = StyleSheet.create({
  stars: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: useViewportDimensions(6, 'vh'),
    width: '100%',
  },
});
