import * as React from 'react';
import { View } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import SignUpForm from 'src/components/forms/SignUpForm';
import LogoColor from 'src/components/svgs/LogoColor';
import Layout from 'src/layout/Layout';
import LogoComponent from 'src/layout/component/LogoComponent';

export default function SignUpScreen({ navigation }) {
  const { GlobalFunctions } = React.useContext(GlobalContext);

  return (
    <Layout NavBarDisabled>
      <View style={{ height: '100%', justifyContent: 'center' }}>
        <LogoColor style={{ width: 200, height: 40, alignSelf: 'center' }} />
        <SignUpForm onSubmit={GlobalFunctions.signUp} navigation={navigation} />
      </View>
    </Layout>
  );
}
