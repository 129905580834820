import DateTimePicker from '@react-native-community/datetimepicker';
import React, { useState } from 'react';
import { View, Button, Platform, StyleSheet, Text } from 'react-native';

import CustomButton from '../components/CustomButton';

const DatePickerCustom = ({ onChangeDate }) => {
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === 'ios');
    setDate(currentDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('es-ES', options);
    onChangeDate(formattedDate);
  };

  const showPicker = () => {
    setShow(true);
  };

  return (
    <View>
      <CustomButton type="SECONDARY" text="ELIGE UNA FECHA" onPress={showPicker} />
      {show && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode="date"
          is24Hour
          display="default"
          onChange={onChange}
        />
      )}
    </View>
  );
};

// Add a web version using React's built-in date picker component
const DatePickerCustomWeb = ({ onChangeDate }) => {
  const [date, setDate] = useState(new Date());

  const onChange = (event) => {
    const selectedDate = new Date(event.target.value);
    setDate(selectedDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = selectedDate.toLocaleDateString('es-ES', options);
    onChangeDate(formattedDate);
  };

  return (
    <View>
      <label style={styles.text} htmlFor="datepicker">
        ELIGE UNA FECHA:
      </label>
      <input
        style={styles.text}
        type="date"
        id="datepicker"
        value={date.toISOString().split('T')[0]}
        onChange={onChange}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    color: '#527186',
    fontFamily: 'asap-regular',
    fontSize: 20,
    fontWeight: '500',
  },
});

export default Platform.select({
  ios: DatePickerCustom,
  android: DatePickerCustom,
  default: DatePickerCustomWeb, // Use the web version by default
});
