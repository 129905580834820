import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function StarSVG(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fill="#ffb400"
        d="M3.488 13.184l6.272 6.112-1.472 8.608L16 23.84l7.712 4.064-1.472-8.608 6.272-6.112-8.64-1.248L16 4.128l-3.872 7.808z"
      />
    </Svg>
  );
}
