import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getSavedServerReloadState() {
  try {
    const jsonValue = await AsyncStorage.getItem('serverReloadNeeded');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    console.log(e);
  }
}

export async function getSavedStateDataState() {
  try {
    const jsonValue = await AsyncStorage.getItem('dataSavedState');
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    console.log(e);

    /////////
  }
}

export async function setServerReload() {
  try {
    await AsyncStorage.setItem('serverReloadNeeded', 'false');
  } catch (e) {
    // saving error
    console.log(e);
  }
}
export async function setLastQuestion(question, hotelId) {
  try {
    await AsyncStorage.setItem('lastQuestions' + hotelId, question + '');
  } catch (e) {
    // saving error
    console.log(e);
  }
}
export async function getLastQuestion(hotelId) {
  try {
    const jsonValue = await AsyncStorage.getItem('lastQuestions' + hotelId);
    return jsonValue != null ? JSON.parse(jsonValue) : 0;
  } catch (e) {
    // saving error
    console.log(e);
  }
}

export async function setSavedState(state) {
  try {
    const dataRecorded = JSON.stringify(state.pendingForms);
    await AsyncStorage.setItem('dataSavedState', dataRecorded);
  } catch (e) {
    // saving error
    console.log(e);
  }
}
export async function removeSavedStateData(sendData, GlobalFunctions) {
  try {
    await GlobalFunctions.globalSendAnsweredQuestion(sendData);
    await AsyncStorage.setItem('serverReloadNeeded', 'true');
  } catch (e) {
    console.log('put error', e);
  }
}
