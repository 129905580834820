import { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import PillComponent from 'src/components/PillComponent';
import MediumHeading from 'src/components/headings/MediumHeading';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

/* Common Components */

/* Screen Components */
import HotelDetailsSlider from './components/HotelDetailsSlider';
import RenderedStars from './components/RenderedStars';

const Services = ({ services }) =>
  services.map((service, i) => <PillComponent key={i} text={service} />);

export default function HotelDetailsScreen({ route, navigation }) {
  const { hotel } = route.params;

  return (
    <Layout navigation={navigation}>
      <BegekkoBreadCrumb text="Volver" />
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.hotel_title}>{hotel.name}</Text>
        <Text>
          <RenderedStars amount={hotel.stars} />
        </Text>
        <Text style={styles.hotel_address}>{hotel.address}</Text>
      </View>
      <HotelDetailsSlider mainImage={hotel.mainImage} />

      <MediumHeading text="Sobre el Hotel" />
      <View style={styles.description}>
        <Text>{hotel.description}</Text>
        <View style={styles.bold_wrapper}>
          <Text style={styles.text_bold}>Precio por Noche: </Text>
          <Text>{hotel.price}</Text>
        </View>
        <View style={styles.bold_wrapper}>
          <Text style={styles.text_bold}>Estrellas: </Text>
          <RenderedStars amount={hotel.stars} />
        </View>
      </View>
      <MediumHeading text="Servicios" />
      <View style={styles.service_pills}>
        <Services services={hotel.services} />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    bold_wrapper: {
      flexDirection: 'row',
      marginTop: 20,
    },
    description: {
      marginBottom: 25,
    },
    service_pills: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
  },
});
