import React from 'react';
import { SafeAreaView, TextInput, StyleSheet } from 'react-native';

const CustomInput = ({
  secureTextEntry = false,
  onBlur,
  onChangeText,
  value,
  placeholder,
  autocomplete,
  multiline,
  isBlank,
}) => {
  return (
    <SafeAreaView style={styles.container}>
      <TextInput
        onChangeText={onChangeText}
        defaultValue={value}
        onBlur={onBlur}
        placeholder={placeholder}
        style={isBlank ? styles.input_BLANK : styles.input}
        secureTextEntry={secureTextEntry}
        autocomplete={autocomplete}
        multiline={multiline}
        numberOfLines={multiline ? 4 : 1}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { margin: 0, width: '100%' },
  input: {
    backgroundColor: '#E3EDF4',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#8BA5BD',
    fontFamily: 'asap-regular',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
    marginTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    placeholderTextColor: '#8BA5BD',
    textAlignVertical: 'top',
    width: '100%',
  },
  input_BLANK: {
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderTopWidth: 2,
    color: '#5B7E96',
    fontFamily: 'asap-regular',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    placeholderTextColor: '#C3C3C3',
    textAlignVertical: 'top',
    width: '100%',
  },
});

export default CustomInput;
