import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function LogoColor(props) {
  return (
    <Svg
      viewBox="0 0 459 92"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}>
      <G transform="matrix(.96632 0 0 .87619 -54.114 -210.286)">
        <Path d="M56 240H531V345H56z" fill="none" />
        <Path
          d="M.402 0C.452 0 .5-.02.535-.053a.17.17 0 00.053-.123.175.175 0 00-.03-.099.16.16 0 00-.071-.057v-.005a.145.145 0 00.044-.045A.131.131 0 00.55-.45a.146.146 0 00-.037-.099A.169.169 0 00.385-.6h-.32V0h.337zm.126-.176a.12.12 0 01-.033.087.131.131 0 01-.095.036H.125V-.3h.279c.039 0 .075.016.098.044a.124.124 0 01.026.08zM.49-.45a.098.098 0 01-.024.065.089.089 0 01-.069.032H.125v-.194h.253c.036 0 .064.011.083.028A.093.093 0 01.49-.45z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(66.826 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.3-.509a.26.26 0 00-.191.073.258.258 0 00-.07.186.25.25 0 00.077.193.305.305 0 00.206.066c.07 0 .126-.01.179-.034L.483-.074a.457.457 0 01-.161.028C.251-.046.19-.063.153-.1A.183.183 0 01.1-.218h.461V-.25a.264.264 0 00-.067-.192C.451-.486.386-.509.3-.509zm0 .055c.068 0 .118.016.151.05.029.03.046.073.049.131H.099a.204.204 0 01.047-.121.198.198 0 01.154-.06z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(120.496 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.369-.27l.145.01v.134A.339.339 0 01.36-.09a.18.18 0 01-.151-.07.23.23 0 01-.042-.14c0-.069.023-.125.066-.161a.235.235 0 01.155-.051.5.5 0 01.191.038l.036-.085a.49.49 0 00-.227-.052.37.37 0 00-.252.083.296.296 0 00-.099.228c0 .094.029.168.081.222a.313.313 0 00.233.089.339.339 0 00.179-.049L.55 0h.089v-.355h-.27v.085z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(171.586 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.308-.509a.279.279 0 00-.197.074.246.246 0 00-.074.185c0 .079.029.146.08.192a.327.327 0 00.215.067.473.473 0 00.196-.038L.503-.111a.511.511 0 01-.168.025c-.05 0-.101-.012-.13-.039a.12.12 0 01-.038-.076H.58v-.043a.26.26 0 00-.078-.201C.457-.488.391-.509.308-.509zm.002.095c.044 0 .079.01.103.032a.13.13 0 01.041.088h-.29a.146.146 0 01.041-.083.15.15 0 01.105-.037z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(231.105 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.062-.65V0h.123v-.22H.32L.448 0h.137L.42-.272.578-.5H.441l-.123.183H.185V-.65H.062z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(283.658 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.062-.65V0h.123v-.22H.32L.448 0h.137L.42-.272.578-.5H.441l-.123.183H.185V-.65H.062z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(335.006 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <Path
          d="M.31-.509a.291.291 0 00-.194.068.247.247 0 00-.079.191c0 .082.029.146.079.191A.291.291 0 00.31.009a.288.288 0 00.194-.068.247.247 0 00.08-.191.247.247 0 00-.08-.191.288.288 0 00-.194-.068zm0 .096c.046 0 .084.016.109.043a.168.168 0 01.041.12.168.168 0 01-.041.12.145.145 0 01-.109.043.147.147 0 01-.109-.043.172.172 0 01-.04-.12c0-.051.014-.092.04-.12A.147.147 0 01.31-.413z"
          transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(384.892 339.182) scale(86.0103)"
          fill="#5b7e96"
          fillRule="nonzero"
        />
        <G>
          <Path
            d="M0-17.855h-35.71V0h17.855c0 9.861-7.994 17.855-17.855 17.855-9.86 0-17.854-7.993-17.855-17.854v-.002c.001-9.861 7.995-17.854 17.855-17.854v-17.854a35.537 35.537 0 00-17.855 4.788v-22.644H-71.42V.395h.01c.213 19.539 16.111 35.315 35.7 35.315C-15.988 35.71 0 19.722 0 0v-17.855z"
            transform="matrix(1.03486 0 0 1.1413 -17.475 -47.609) translate(528.45 306.566)"
            fill="#d2d745"
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
}

export default LogoColor;
