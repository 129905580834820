import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View, Text, TouchableOpacity, Animated } from 'react-native';

export default function LinkText({ routeTo, text, ...props }) {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={{
        alignSelf: 'center',
        textAlign: 'center',
      }}
      onPress={() => navigation.navigate(routeTo)}>
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    color: '#5B7E96',
    fontFamily: 'asap-regular',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 15,
    textAlign: 'center',
  },
});
