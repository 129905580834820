import { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Text, View, StyleSheet, Picker } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';

import CustomButton from '../CustomButton';
import CustomInput from '../CustomInput';

export default function SignUpForm({ onSubmit, navigation }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      role: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmitForm = (data) => {
    onSubmit(data);
  };

  return (
    <View style={{ padding: 40 }}>
      <BegekkoBreadCrumb text="Volver" />
      {state.isLoginError ? (
        <Text style={{ color: 'red' }}>Contraseña o usuario equivocado</Text>
      ) : (
        ''
      )}

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Picker
              style={styles.picker}
              onBlur={onBlur}
              onValueChange={onChange}
              selectedValue={value}>
              <Picker.Item label="Selecciona tu rol" value="select" />
              <Picker.Item label="Soy Hotelero" value="hotelier" />
              <Picker.Item label="Soy Profesional" value="gekko" />
            </Picker>
            {errors?.role?.type === 'required' && (
              <Text style={{ color: 'red' }}>Selecciona tu rol</Text>
            )}
          </>
        )}
        name="role"
      />
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 3,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput placeholder="Nombre" onBlur={onBlur} onChangeText={onChange} value={value} />
        )}
        name="first_name"
      />
      <View style={styles.validation}>
        {errors?.first_name?.type === 'minLength' && (
          <Text style={{ color: 'red' }}>El nombre debe tener al menos 3 caracteres</Text>
        )}
        {errors?.first_name?.type === 'required' && (
          <Text style={{ color: 'red' }}>Complete su nombre</Text>
        )}
      </View>

      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 4,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput
            placeholder="Apellidos"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
          />
        )}
        name="last_name"
      />
      <View style={styles.validation}>
        {errors?.last_name?.type === 'minLength' && (
          <Text style={{ color: 'red' }}>El apellido debe tener al menos 4 caracteres</Text>
        )}
        {errors?.last_name?.type === 'required' && (
          <Text style={{ color: 'red' }}>Complete su apellido</Text>
        )}
      </View>

      <Controller
        control={control}
        rules={{
          required: true,
          message: 'Tiene que llenar su correo electronico',
          pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput
            autocomplete="email"
            placeholder="Correo electrónico"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
          />
        )}
        name="email"
      />
      <View style={styles.validation}>
        {errors?.email?.type === 'pattern' && (
          <Text style={{ color: 'red' }}>No es un correo valido</Text>
        )}
        {errors?.email?.type === 'required' && (
          <Text style={{ color: 'red' }}>Complete su correo</Text>
        )}
      </View>

      <Controller
        control={control}
        rules={{
          required: true,
          maxLength: 100,
          minLength: 8,
          pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]{8,}$',
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput
            placeholder="Contraseña"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            secureTextEntry
          />
        )}
        name="password"
      />
      <View style={styles.validation}>
        {errors?.password?.type === 'maxLength' && (
          <Text style={{ color: 'red' }}>Demasiado largo</Text>
        )}
        {errors?.password?.type === 'minLength' && (
          <Text style={{ color: 'red' }}>Demasiado corto</Text>
        )}
        {errors?.password?.type === 'required' && (
          <Text style={{ color: 'red' }}>Complete su contraseña</Text>
        )}
      </View>
      <Controller
        control={control}
        rules={{
          required: true,
          validate: (value) => value === watch('password') || 'Las contraseñas no coinciden',
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomInput
            placeholder="Confirmar contraseña"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            secureTextEntry
          />
        )}
        name="confirmPassword"
      />
      <View style={styles.validation}>
        {errors?.confirmPassword?.type === 'required' && (
          <Text style={{ color: 'red' }}>Complete su confirmación de contraseña</Text>
        )}
        {errors?.confirmPassword?.type === 'validate' && (
          <Text style={{ color: 'red' }}>{errors.confirmPassword.message}</Text>
        )}
      </View>

      <View style={styles.navigation}>
        <CustomButton type="PRIMARY" text="Registrarse" onPress={handleSubmit(onSubmitForm)} />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  button_container: {
    marginTop: 'auto',
  },
  gap: { width: useViewportDimensions(4, 'vw') },
  gekkoLine: {
    alignSelf: 'center',
    backgroundColor: '#adadad',
    height: 1,
    margin: 25,
    width: '88%',
  },
  navigation: { paddingTop: 50 },

  picker: {
    backgroundColor: '#E3EDF4',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderWidth: 0,
    color: '#8BA5BD',
    fontFamily: 'asap-regular',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
    marginTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    placeholderTextColor: '#8BA5BD',
    textAlignVertical: 'top',
    width: '100%',
  },
  text: {
    fontFamily: 'asap-regular',
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: useViewportDimensions(1, 'vh'),
    paddingTop: useViewportDimensions(5, 'vh'),
  },
  validation: {
    paddingTop: 5,
  },
});
