import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
const CheckList = ({ onValueChanged, list, headerList }) => {
  const [checked, setChecked] = useState([false, false, false, false]);
  const handleCheck = (index) => {
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
    onValueChanged(newChecked.map((value, i) => (value ? i + 1 : '')));
  };

  return (
    <View style={styles.container}>
      <Text style={styles.input}> {headerList}</Text>
      {list.map((label, index) => (
        <TouchableOpacity key={index} onPress={() => handleCheck(index)}>
          <View style={styles.checkBox}>
            {checked[index] ? (
              <Ionicons name="checkmark-circle" size={24} color="green" />
            ) : (
              <Ionicons name="ellipse-outline" size={24} color="black" />
            )}
            <Text style={styles.label}>{label}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#D6DF2A',
    height: useViewportDimensions(1, 'vh'),
    marginTop: useViewportDimensions(2, 'vh'),
    width: '66%',
  },
  checkBox: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  container: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 10,
  },
  input: {
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    color: '#527186',
    fontFamily: 'asap-regular',
    fontFamily: 'EchoesSans-Thin',
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: 17,
    paddingLeft: 17,
    paddingRight: 17,
    paddingTop: 17,
    placeholderTextColor: '#989898',
    textAlign: 'center',
    textAlignVertical: 'top',
    textDecorationColor: 'black',
    textDecorationLine: 'none',
    textDecorationStyle: 'solid',
    width: '100%',
  },
  label: {
    color: '#527186',
    fontFamily: 'EchoesSans-Thin',
    fontSize: 18,
    fontSize: 20,
    marginLeft: 10,
  },
});

export default CheckList;
