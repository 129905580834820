import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from 'react';
import {
  getForm,
  getHotelsForm,
  SendAnsweredQuestion,
  getSignInAttempt,
  getSignUpAttempt,
  getPendingQuestionnaires,
  getHoteldata,
  sendNewForm,
  community,
  sendQuestionnaireAnswersToAPI,
} from 'src/integrations/API';
import {
  getSavedStateDataState,
  getSavedServerReloadState,
  setServerReload,
  setSavedState,
  removeSavedStateData,
  getLastQuestion,
  setLastQuestion,
} from 'src/integrations/AsyncFunctions';

const GlobalContext = React.createContext();

export function GlobalContextWrapper({ children }) {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'REFRESH_STATE':
          return { ...action.state };
        case 'SET_LOADING':
          return {
            ...prevState,
            isLoading: true,
          };
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            userRole: action.role,
            hotelId: action.hotelId,
            userData: action.userData,
            personalData: action.personalData,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isLoading: false,
            isSignout: false,
            userToken: action.token,
            userRole: action.role,
            isLoginError: false,
            userInformation: action.userInformation,
            hotelId: action.hotelId,
            userData: action.userData,
            personalData: action.personalData,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            userRole: null,
            userInformation: null,
            hotelState: null,
            pendingForms: null,
          };
        case 'STORE_HOTEL_STATE':
          return { ...prevState, hotelState: action.hotel_state };

        case 'STORE_PENDING_FORMS':
          return {
            ...prevState,
            isLoading: false,
            pendingForms: action.pending_forms,
          };
        case 'STORE_PENDING_QUESTIONNAIRES':
          return {
            ...prevState,
            isLoading: false,
            pendingQuestionnaires: action.pendingQuestionnaires,
          };
        case 'STORE_COMPLETED_QUESTIONNAIRES':
          return {
            ...prevState,
            [action.request_id + action.department]: true,
          };
        case 'STORE_FORM_STATE':
          return {
            ...prevState,
            isSignout: true,
            pendingForms: action.pendingForms,
          };
        case 'SIGNUP_ERROR':
          return {
            ...prevState,
            isSignupError: true,
            isLoading: false,
          };
        case 'LOGIN_ERROR':
          return {
            ...prevState,
            isLoginError: true,
            isLoading: false,
          };
        case 'STORE_REPUTATION_STATE':
          return {
            ...prevState,
            reputationState: action.reputationState,
          };
        case 'STORE_COMMUNITY_STATE':
          return {
            ...prevState,
            communityState: action.communityState,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      pendingForms: null,
      isLoginError: false,
    }
  );

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken = null;
      let userRole = null;
      let hotelId = null; //??

      // Restore token stored in `SecureStore` or any other encrypted storage
      const token = await AsyncStorage.getItem('@auth_token');
      if (('' + token).length > 8) {
        userToken = token;
      }

      /* Restore Role */
      const role = await AsyncStorage.getItem('@auth_role');
      if (role) {
        userRole = role;

        if (userRole === 'gekko') {
          //const role = await AsyncStorage.getItem('@auth_role');
        }
      }

      const id = await AsyncStorage.getItem('hotelId');
      if (id) {
        hotelId = id;
      }

      let personalData = await AsyncStorage.getItem('personalData');
      let userData = await AsyncStorage.getItem('userData');

      personalData = JSON.parse(personalData);
      userData = JSON.parse(userData);

      // After restoring token, we may need to validate it in production apps

      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.
      dispatch({
        type: 'RESTORE_TOKEN',
        token: userToken,
        role: userRole,
        hotelId,
        userData,
        personalData,
      });
    };

    bootstrapAsync();
  }, []);

  //// Global functions for APP

  const GlobalFunctions = React.useMemo(
    () => ({
      refreshState: (state) => {
        dispatch({ type: 'REFRESH_STATE', state });
      },

      /* Auth */
      signIn: async (data) => {
        dispatch({ type: 'SET_LOADING' });

        const signInAttempt = await getSignInAttempt(data);

        if (!signInAttempt.token) {
          return dispatch({ type: 'LOGIN_ERROR' });
        }

        try {
          await AsyncStorage.setItem('@auth_token', signInAttempt.token);
          await AsyncStorage.setItem('@auth_role', signInAttempt.type);
          await AsyncStorage.setItem('userData', JSON.stringify(signInAttempt.userData));
          await AsyncStorage.setItem('personalData', JSON.stringify(signInAttempt.personalData));
        } catch (e) {
          // saving error
          console.log(e);
        }

        dispatch({
          type: 'SIGN_IN',
          token: signInAttempt.token,
          role: signInAttempt.type,
          userData: signInAttempt.userData,
          personalData: signInAttempt.personalData,
        });
      },

      signOut: async () => {
        try {
          await AsyncStorage.removeItem('@auth_token');
        } catch (e) {}
        return dispatch({ type: 'SIGN_OUT' });
      },

      signUp: async (data) => {
        dispatch({ type: 'SET_LOADING' });

        const signUpAttempt = await getSignUpAttempt(data);

        try {
          await AsyncStorage.setItem('@auth_token', signUpAttempt.token);
          await AsyncStorage.setItem('@auth_role', signUpAttempt.type);
          await AsyncStorage.setItem('userData', JSON.stringify(signUpAttempt.userData));
          await AsyncStorage.setItem('personalData', JSON.stringify(signUpAttempt.personalData));
        } catch (e) {
          // saving error
          console.log(e);
        }

        if (signUpAttempt.token) {
          return dispatch({
            type: 'SIGN_IN',
            token: signUpAttempt.token,
            role: signUpAttempt.type,
            userData: signUpAttempt.userData,
            personalData: signUpAttempt.personalData,
          });
        }
        return dispatch({ type: 'SIGNUP_ERROR' });
      },

      /* Questionnaires */
      syncStoredQuestionnaires: async (token) => {
        // DEBUG, remove
        await AsyncStorage.removeItem('@gekko_questionnaires');

        let storedQuestionnaires = await AsyncStorage.getItem('@gekko_questionnaires');
        storedQuestionnaires = JSON.parse(storedQuestionnaires);
        console.log(storedQuestionnaires);
        if (storedQuestionnaires) {
          return dispatch({
            type: 'STORE_PENDING_QUESTIONNAIRES',
            pendingQuestionnaires: storedQuestionnaires,
          });
        }
        const pendingQuestionnaires = await getPendingQuestionnaires(token);
        if (pendingQuestionnaires) {
          GlobalFunctions.storeQuestionnaires(pendingQuestionnaires);
        }
        //dispatch dashboard error
      },
      storeQuestionnaires: async (pendingQuestionnaires) => {
        await AsyncStorage.setItem('@gekko_questionnaires', JSON.stringify(pendingQuestionnaires));
        return dispatch({
          type: 'STORE_PENDING_QUESTIONNAIRES',
          pendingQuestionnaires,
        });
      },
      saveAnswersLocally: async ({
        currentQuestion,
        currentDependency,
        request_id,
        questionnaireId,
      }) => {
        await AsyncStorage.setItem(`@questionnaire_answers_${request_id}_${questionnaireId}`, {
          currentQuestion,
          currentDependency,
          request_id,
          questionnaireId,
        });
      },
      retrieveSavedAnswers: async ({ request_id, questionnaireId }) => {
        const savedQuestions = await AsyncStorage.getItem(
          `@questionnaire_answers_${request_id}_${questionnaireId}`
        );
        return JSON.parse(savedQuestions) || undefined;
      },
      sendQuestionnaireAnswers: async ({ answers, request_id, department }) => {
        return dispatch({
          type: 'STORE_COMPLETED_QUESTIONNAIRES',
          request_id,
          department,
        });
        const starsMedian = GlobalFunctions.calcStarsMedian({ answers });

        const answersWithStars = answers;
        answers['stars_rank'] = starsMedian;

        const result = await sendQuestionnaireAnswersToAPI({
          answers: answersWithStars,
          request_id,
          department,
        });

        if (result.msg === 'ok') {
          dispatch({ type: 'SET_LOADING' });
          dispatch({
            type: 'STORE_COMPLETED_QUESTIONNAIRES',
            completedQuestionnaires: { [request_id]: department },
          });
        }
      },
      calcStarsMedian: ({ answers }) => {
        const starsQuestions = [];
        const dependencies = Object.values(answers);
        dependencies.forEach((dependency) => {
          const questions = Object.values(dependency);
          questions.forEach((question) => {
            const number = parseInt(question);
            if (!isNaN(number)) {
              starsQuestions.push(number);
            }
          });
        });
        const sum = starsQuestions.reduce((previousSum, actualNum) => previousSum + actualNum);
        const median = sum / starsQuestions.length;
        return median;
      },

      /* Dashboard */
      loadGekkoDashboard: async (token) => {
        await GlobalFunctions.syncStoredQuestionnaires(token); //
      },

      ////Async reload saved state for gekkos
      globalGetSavedStateDataState: async () => {
        getSavedStateDataState();
      },
      //call to API for retrive hotelier data
      globalGetHotelsData: async (userToken) => {
        let hotel_State;
        await getHoteldata(userToken).then((result) => {
          hotel_State = result;
        });
        console.log(hotel_State, '-get hotel data from axios');
        try {
          dispatch({
            type: 'STORE_HOTEL_STATE',
            hotel_state: hotel_State,
          });
        } catch (e) {
          console.log(e);
        }
      },

      globalGetCommunityData: async (userToken) => {
        let communityState;
        await community().then((result) => {
          communityState = result;
        });
        console.log(communityState, '-get reputationl data from axios');
        try {
          dispatch({
            type: 'STORE_COMMUNITY_STATE',
            communityState: communityState.data,
          });
        } catch (e) {
          console.log(e);
        }
      },

      /////call to Api to retrive forms list
      globalGetPendingForms: async (userToken, reloadNeed) => {
        let formsArray = [];
        await getSavedStateDataState().then((result) => {
          formsArray = result;
        });
        await getSavedServerReloadState().then((result) => {
          result == true ? (reloadNeed = result) : '';
        });
        if (formsArray === null || reloadNeed || formsArray == undefined) {
          await setServerReload();
          let mockForm;
          await getForm(userToken).then((res) => {
            mockForm = JSON.parse(JSON.stringify(res));
          });

          // Set loading state
          dispatch({ type: 'SET_LOADING' });

          let formsArray = [];
          await getHotelsForm(userToken).then((res) => {
            if (res == 'error') {
              dispatch({ type: 'SIGN_IN', role: 'gekko' });
              return;
            }
            formsArray = JSON.parse(JSON.stringify(res.data.pending_forms));
          });

          try {
            formsArray.forEach((val, index) => {
              const copy = JSON.parse(JSON.stringify(mockForm));
              formsArray[index] = [val, copy];
            });
            formsArray.forEach((val, index) => {
              setLastQuestion(0, index);
              formsArray[index][2] = { lastQuestion: '0' };
            });
            console.log(formsArray, 'forms array');
            await dispatch({
              type: 'STORE_PENDING_FORMS',
              pending_forms: formsArray,
              //  form_questions: mockForm,
            });
            await setSavedState(state);
          } catch (e) {}
        } else {
          const fullFormsAray = JSON.parse(JSON.stringify(formsArray));
          formsArray.forEach((val, index) => {
            getLastQuestion(index).then((res) => {
              fullFormsAray[index][2] = { lastQuestion: res };
            });
          });
        }
      },
      ///send new form to server
      globalSendNewForm: async (data) => sendNewForm(data),

      ///// Set gokkos app state
      globalSetSavedState: async (state) => {
        setSavedState(state);
      },

      ///// Set new retrived forms for gekkos
      globalSetNewPendingForms: async (formsArray) => {
        try {
          dispatch({
            type: 'STORE_PENDING_FORMS',
            pending_forms: formsArray,
            //  form_questions: mockForm,
          });
        } catch (e) {
          console.log(e);
        }
      },

      ////send answered Question to API
      globalSendAnsweredQuestion: async (storedDataToSend) => {
        await SendAnsweredQuestion(storedDataToSend).catch((e) => {
          console.log(e, 'error post');
        });
      },

      ///Set last answered questions for gekko
      globalSetLastQuestion: async (question, hotelId) => {
        setLastQuestion(question, hotelId);
      },

      ////clear state for gekko
      globalRemoveSavedStateData: async (data) => {
        await removeSavedStateData(data, GlobalFunctions);
      },
      ////store form state for gekkos
      globalStoreFormState: async (orgState, hotelId, questionIndex, value) => {
        orgState[hotelId][1].form[questionIndex].value = value;
        orgState[hotelId][2].lastQuestion = questionIndex;
        dispatch({ type: 'STORE_FORM_STATE', pendingForms: orgState });
      },
    }),
    []
  );
  return (
    <GlobalContext.Provider value={{ GlobalFunctions, state }}>{children}</GlobalContext.Provider>
  );
}

export default GlobalContext;
