import { useRoute } from '@react-navigation/native';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { View, Text, ScrollView, Pressable, StyleSheet, Animated } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import NavBar from 'src/layout/NavBar';
import CommonStyles from 'src/styles/CommonStyles';

import NavbarButton from './component/NavbarButton';
import NavbarSignOutButton from './component/NavbarSignOutButton';
export default function Layout({ NavBarDisabled, children, navigation }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const [panelVisible, setPanelVisible] = useState(false);
  const panelPosition = useRef(new Animated.Value(0)).current;
  const route = useRoute();
  const togglePanel = () => {
    console.log('toggled');
    Animated.timing(panelPosition, {
      toValue: panelVisible ? 0 : useViewportDimensions(60, 'vh'),
      duration: 300,
      useNativeDriver: true,
    }).start();
    setPanelVisible(!panelVisible);
  };

  return (
    <View style={styles.container} key={navigation}>
      <NavBar isDisabled={NavBarDisabled} navigation={navigation} togglePanel={togglePanel} />
      <ScrollView contentContainerStyle={styles.scroll}>{children}</ScrollView>
      <Animated.View
        style={[
          styles.panel,
          {
            transform: [
              {
                translateY: panelPosition,
              },
            ],
          },
        ]}>
        <Pressable onPress={togglePanel} style={styles.closeButton}>
          <Text style={styles.closeButtonText}>X</Text>
        </Pressable>
        <NavbarButton
          text="Dashboard"
          routeName={state.userRole === 'gekko' ? 'GekkoDashboardScreen' : 'HotelDashboardScreen'}
          actualRoute={route.name}
          togglePanel={togglePanel}
        />
        {state.userRole === 'gekko' ? (
          /* User Gekko */ <NavbarButton
            text="Hoteles"
            routeName="HotelListScreen"
            actualRoute={route.name}
            togglePanel={togglePanel}
          />
        ) : null}
        {state.userRole === 'hotelier' ? (
          /* User Hotelier */
          <>
            <NavbarButton
              text="Ofertas"
              routeName="OfferScreen"
              actualRoute={route.name}
              togglePanel={togglePanel}
            />
            <NavbarButton
              text="Informes"
              routeName="InformesScreen"
              actualRoute={route.name}
              togglePanel={togglePanel}
            />
            <NavbarButton
              text="Comunidad"
              routeName="ComunityScreen"
              actualRoute={route.name}
              togglePanel={togglePanel}
            />
          </>
        ) : null}
        <NavbarSignOutButton text="CERRAR SESIÓN" />
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    container: {
      flex: 1,
      position: 'relative',
    },
    scroll: {
      backgroundColor: '#f6f7fa',
      flexGrow: 1,
      fontFamily: 'asap-regular',
      fontSize: 18,
      justifyContent: 'space-between',
      paddingHorizontal: 16,
      paddingVertical: 32,
    },
    panel: {
      //height: useViewportDimensions(35, 'vh'),
      position: 'absolute',
      width: '100%',
      top: -useViewportDimensions(60, 'vh'),
      left: 0,
      backgroundColor: '#FFFF',
      textAlign: 'right',
      alignItems: 'right',
      justifyContent: 'right',
      backgroundColor: '#FFFFFF',
      borderWidth: 1,
      borderColor: '#ECECEC',
      borderStyle: 'solid',
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
    },
    closeButton: {
      flex: 1,
      height: 70,
      width: '100%',
      padding: 30,
      textAlign: 'right',
      alignItems: 'right',
      justifyContent: 'right',
    },
    closeButtonText: {
      textAlign: 'right',
      fontSize: 30,
    },
  },
});
