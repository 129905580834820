import { View, Text, StyleSheet } from 'react-native';

export default function BigHeading({ text }) {
  return (
    <View>
      <Text style={styles.title}>{text}</Text>
      <Text style={styles.after} />
    </View>
  );
}

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#D6DF2A',
    height: 1,
    marginBottom: 20,
    marginTop: 5,
    width: 100,
  },
  title: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Medium',
    fontSize: 26,
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
