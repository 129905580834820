import React, { useEffect, useContext, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import CreateOfferForm from 'src/components/forms/CreateOfferForm';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';

import CheckList from '../../components/CheckList ';
import CustomInput from '../../components/CustomInput';
import DatePickerCustom from '../../components/DatePicker';
import SelectField from '../../components/SelectField';

export default function NewOfferScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const [departments, setDepartment] = useState([]);
  const [proffesionaLevel, setProffeccionalLevel] = useState('');
  const [howToMake, setHowToMake] = useState('');
  const [mandatoryActions, setMandatoryActions] = useState('');
  const [extraValues, setExtraValues] = useState('');
  const [night, setNight] = useState('');
  const [discount, setDiscount] = useState('');
  const [dateSelected, setDateSelected] = useState('');
  const [customQuestion, setCustomQuestion] = useState('');

  const dataSend = {
    departments: JSON.stringify(
      Object.assign(
        {},
        departments.filter((val) => {
          if (val != '') {
            return val;
          }
        })
      )
    ),
    professional_level: proffesionaLevel,
    how_to_book: howToMake,
    mandatory_actions: mandatoryActions, ////////
    extra_values: extraValues, //////////
    consec_nights: night,
    consec_nights_disc: discount,
    custom_questions: JSON.stringify(Object.assign({}, [customQuestion])),
    date_limit: dateSelected,
    hotel_id: state.hotelId,
  };

  console.log(dataSend);

  return (
    <Layout navigation={navigation}>
      <CreateOfferForm />
      <View style={styles.card}>
        <CheckList
          onValueChanged={(item) => {
            setDepartment(item);
          }}
          headerList="DEPARTAMENTO"
          list={['Bienvenido', 'Experiencia', 'F&B', 'Estatus digital y Mkt']}
        />
      </View>

      <Text style={styles.after} />
      <View style={styles.card}>
        <SelectField
          placeHolder="Nivel profecional"
          label={['Manager', 'Director', 'CEO', 'Owner']}
          onValueChanged={(item) => {
            switch (item) {
              case 'Manager':
                item = 1;
              case 'Director':
                item = 2;
              case 'CEO':
                item = 3;
              case 'Owner':
                item = 4;
            }

            setProffeccionalLevel(item);
          }}
        />
      </View>
      <Text style={styles.after} />

      <View style={styles.textInput}>
        <Text style={styles.text}> Mandatory Actions </Text>
        <CustomInput
          placeholder="Mandatory Actions"
          multiline
          onChangeText={(val) => {
            setMandatoryActions(val);
          }}
        />
      </View>
      <Text style={styles.after} />
      <View style={styles.textInput}>
        <Text style={styles.text}> Extra Values </Text>
        <CustomInput
          placeholder="Extra Values"
          multiline
          onChangeText={(val) => {
            setExtraValues(val);
          }}
        />
      </View>

      <Text style={styles.after} />
      <View style={styles.card}>
        <SelectField
          placeHolder="Como hace la resrva Gekko"
          label={['En Efectivo', 'Transferencia', 'Targeta']}
          onValueChanged={(item) => {
            setHowToMake(item);
          }}
        />
      </View>
      <Text style={styles.after} />

      <View style={styles.textInput}>
        <Text style={styles.text}> Noches consecutivas </Text>
        <CustomInput
          placeholder="Noches consecutivas"
          multiline={false}
          onChangeText={(val) => {
            setNight(val);
          }}
        />
      </View>
      <Text style={styles.after} />
      <View style={styles.textInput}>
        <Text style={styles.text}> Descuento % </Text>
        <CustomInput
          placeholder="Descuento %"
          multiline={false}
          onChangeText={(val) => {
            setDiscount(val);
          }}
        />
      </View>

      <Text style={styles.after} />
      <View style={styles.textInput}>
        <Text style={styles.text}> Preguntas customizadas </Text>

        <CustomInput
          placeholder="Preguntas customizadas"
          multiline
          onChangeText={(val) => {
            setCustomQuestion(val);
          }}
        />
      </View>
      <Text style={styles.after} />
      <View style={styles.card}>
        <Text style={styles.text}> {dateSelected}</Text>
      </View>

      <DatePickerCustom
        onChangeDate={(val) => {
          setDateSelected(val);
        }}
      />

      <View style={styles.textInput} />
      <Text style={styles.after} />
      <View style={{ marginTop: 15 }}>
        <CustomButton
          type="PRIMARY"
          text="ENVIAR FORMULARIO"
          onPress={() => {
            console.log('here API call');
            GlobalFunctions.globalSendNewForm(dataSend);
            console.log(dataSend, 'data sended');
            navigation.navigate('OfferSentScreen');
          }}
        />
      </View>
      <View style={{ marginTop: 15 }}>
        <CustomButton
          type="PRIMARY"
          text="ATRAS"
          onPress={() => {
            navigation.navigate('OfferScreen');
          }}
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#D6DF2A',
    height: useViewportDimensions(1, 'vh'),
    marginTop: useViewportDimensions(2, 'vh'),
    width: '66%',
  },
  card: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    marginVertical: 'auto',
  },
  card_body: {
    padding: 20,
  },
  card_footer: {
    borderColor: '#ececec',
    borderTopWidth: 15,
    color: 'white',
    textAlign: 'left',
  },
  card_text: {
    color: 'white',
    fontFamily: 'asap-regular',
    fontSize: 25,
  },
  card_title: {
    color: 'white',
    fontSize: 25,
    marginBottom: 20,
  },
  text: {
    alignSelf: 'center',
    color: '#527186',
    fontFamily: 'asap-regular',
    fontSize: 20,
    fontWeight: '500',
    marginBottom: 10,
    marginTop: 'auto',
    marginTop: 20,
  },
  textInput: {
    marginTop: 15,
  },
  title: {
    fontFamily: 'asap-regular',
    fontSize: 25,
  },
});
