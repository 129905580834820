import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import GlobalContext from 'src/GlobalContext';
const NavbarSignOutButton = ({ text }) => {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  return (
    <Pressable onPress={GlobalFunctions.signOut} style={styles.container}>
      <Text style={styles.button}>{text}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  after: {
    alignSelf: 'center',
    backgroundColor: '#5c7e96',
    height: 2,
    width: '11%',
  },
  button: {
    color: '#5C7E96',
    fontFamily: 'EchoesSans-Medium',
    fontSize: 16,
    fontWeight: '500',
    lineHeigh: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    alignSelf: 'stretch',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    marginBottom: 15,
    marginHorizontal: 20,
    marginTop: 0,
    padding: 9,
  },
});

export default NavbarSignOutButton;
