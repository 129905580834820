import { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Text, View, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import useViewportDimensions from 'src/integrations/useViewportDimensions';

import CommonStyles from '../../styles/CommonStyles';
import BlueInput from '../BlueInput';
import CustomButton from '../CustomButton';
import CustomInput from '../CustomInput';
import LinkText from '../LinkText';
export default function SignInForm({ onSubmit, navigation }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmitForm = (data) => {
    onSubmit(data);
  };

  return (
    <View style={{ padding: 40 }}>
      <View>
        {state.isLoginError ? (
          <Text style={{ color: 'red' }}>Contraseña o usuario equivocado</Text>
        ) : null}
        <Controller
          control={control}
          rules={{
            required: true,
            message: 'Tiene que llenar su correo electronico',
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInput
              autocomplete="email"
              placeholder="Correo electrónico"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="email"
        />
        <View style={styles.validation}>
          {errors?.email?.type === 'pattern' && (
            <Text style={{ color: 'red' }}>No es un correo valido</Text>
          )}
          {errors?.email?.type === 'required' && (
            <Text style={{ color: 'red' }}>Complete su correo</Text>
          )}
        </View>
        <Controller
          control={control}
          rules={{
            required: true,
            maxLength: 100,
            minLength: 8,
            pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]{8,}$',
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInput
              placeholder="Contraseña"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry
            />
          )}
          name="password"
        />
        <View style={styles.validation}>
          {errors?.password?.type === 'maxLength' && (
            <Text style={{ color: 'red' }}>Demasiado largo</Text>
          )}
          {errors?.password?.type === 'minLength' && (
            <Text style={{ color: 'red' }}>Demasiado corto</Text>
          )}
          {errors?.password?.type === 'required' && (
            <Text style={{ color: 'red' }}>Complete su contraseña</Text>
          )}
        </View>
      </View>
      <View style={styles.navigation}>
        <CustomButton type="PRIMARY" text="INICIAR SESIÓN" onPress={handleSubmit(onSubmitForm)} />
        <View style={styles.gekkoLine} />
        <Text style={[styles.text_small, styles.text_muted, styles.text_center]}>
          ¿Todavía no tiene una cuenta?
        </Text>
        <Text style={[styles.text_small, styles.text_muted, styles.text_center]}>
          <LinkText text="Registrarse" routeTo="SignUp" />
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    button_container: {
      marginTop: 'auto',
    },
    navigation: { paddingTop: 50, paddingBottom: 50 },
    gekkoLine: {
      margin: 25,
      width: '88%',
      height: 1,
      backgroundColor: '#adadad',
      alignSelf: 'center',
    },

    text: {
      paddingTop: useViewportDimensions(5, 'vh'),
      paddingBottom: useViewportDimensions(1, 'vh'),
      fontFamily: 'asap-regular',
      fontWeight: '600',
      fontSize: 20,
    },
    validation: {
      paddingTop: 5,
    },
    gap: { width: 3 },
  },
});
