import { useNavigation } from '@react-navigation/native';
import Loader from 'assets/loading.svg';
import { useState, useEffect, useContext } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import MediumHeading from 'src/components/headings/MediumHeading';
import { getPending } from 'src/integrations/API';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import CommonStyles from 'src/styles/CommonStyles';

/* Web API */

/* Components */

export default function GekkoRequestDetailsScreen({ route, navigation, ...props }) {
  const { state } = useContext(GlobalContext);
  const { requestId, hotelName } = route.params;
  const [isLoading, setIsLoading] = useState(true);

  const [questionnaires, setQuestionnaires] = useState([]);

  useEffect(() => {
    const loadPendingQuestionnaires = async () => {
      const questionnairesResponse = await getPending({
        token: state.userToken,
        requestId,
      });
      if (questionnairesResponse) {
        setQuestionnaires(questionnairesResponse);
        setIsLoading(false);
      }
    };
    loadPendingQuestionnaires();
  }, []);

  if (isLoading) {
    return <Image source={Loader} style={{ flex: 1 }} resizeMode="contain" />;
  }

  return (
    <Layout navigation={navigation}>
      <View>
        <BegekkoBreadCrumb text={`${hotelName}`} />

        <View style={styles.card}>
          <View style={styles.card_title}>
            <Text style={styles.card_title_text}>Cuestionarios pendientes:</Text>
          </View>

          {Object.keys(questionnaires).map((key, i) => (
            <CustomButton
              key={i}
              style={[{ textAlign: 'left' }, styles.border_bottom]}
              type="THIRD"
              text={key}
              onPress={() => {
                navigation.navigate('QuestionnairesQuestionsScreen', {
                  questionnaire: questionnaires[key],
                  requestId,
                  key,
                });
              }}
            />
          ))}
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create(CommonStyles);
