import { useNavigation } from '@react-navigation/native';

import HotelListItem from './HotelListItem';

export default function HotelListComponent({ arrayHotels }) {
  const navigation = useNavigation();
  return (
    <>
      {arrayHotels.map((hotel, i) => (
        <HotelListItem
          key={i}
          name={hotel.name}
          address={hotel.address}
          price={hotel.price}
          stars={hotel.stars}
          mainImage={hotel.mainImage}
          onPress={() => navigation.navigate('HotelDetailsScreen', { hotel })}
        />
      ))}
    </>
  );
}
