import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function MenuSVG(props) {
  return (
    <Svg
      width={35}
      height={35}
      viewBox="0 0 31 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M12 18.333c0 .92.746 1.667 1.667 1.667h15.666a1.667 1.667 0 100-3.333H13.667c-.92 0-1.667.746-1.667 1.666zM0 10c0 .92.746 1.667 1.667 1.667h27.666a1.667 1.667 0 100-3.334H1.667C.747 8.333 0 9.08 0 10zM1.667 0a1.667 1.667 0 100 3.333h27.666a1.667 1.667 0 100-3.333H1.667z"
        fill="#5C7E96"
      />
    </Svg>
  );
}

export default MenuSVG;
