import React, { useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ScrollView, SafeAreaView, Image } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';

export default function UserHotelierScreen({ route, navbar, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const profile = JSON.parse(state.personalData);

  // Log the parsed profile data to the console for debugging purposes
  console.log(profile, 'profile JSON');

  // Destructure the properties of the profile object for easier access
  let {
    user_id,
    avatar,

    birthday,
    city,
    country,
    created_at,
    featured_image,
    gender,
    job_title,
    linkedin_url,
    phone,
    professional_level,
    region,
    score,
    summary,
    updated_at,
    zip,
  } = profile;
  avatar = 'https://begekko.com/images/users/' + user_id + '/avatar/' + avatar;
  featured_image = featured_image.replace(/\.png$/, '-sm.png');
  featured_image = 'https://begekko.com/images/profile/' + featured_image;

  // Log the hotelier state to the console for debugging purposes
  console.log(state, 'hotelier state');

  return (
    <Layout navigation={navigation}>
      <View style={styles.card}>
        <View style={styles.card_body}>
          <View style={styles.container}>
            <Text style={styles.card_text}>
              <Text style={styles.text1}>Nombre:</Text> Hotelier
            </Text>
            <Image source={{ uri: avatar }} style={styles.avatar} />
            <View style={styles.info}>
              {/* Display the profile data */}

              <Text style={styles.card_text}>
                <Text style={styles.text1}>Fecha de nacimiento: </Text>
                {birthday}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Sitio: </Text> {city}, {country}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Joined: </Text>
                {created_at.substring(0, 10)}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Genero: </Text>
                {gender}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Puesto del trabajo:</Text> {job_title}
              </Text>
              <Text style={styles.card_text}>
                <Text style={styles.text1}>Telefono: </Text>
                {phone}
              </Text>
            </View>
          </View>
        </View>
        <Image source={{ uri: featured_image }} style={styles.featuredImage} />
      </View>
      <View style={{ marginTop: 10 }}>
        {/* Add custom buttons */}
        <CustomButton
          type="SECONDARY"
          onPress={() => {
            navigation.navigate('HotelDashboardScreen');
          }}
          text="< VOLVER"
        />
        <View style={{ marginTop: 10 }}>
          <CustomButton type="PRIMARY" text="CERRAR SESIÓN" onPress={GlobalFunctions.signOut} />
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 25,
    height: 50,
    marginBottom: 10,
    marginTop: 10,
    width: 50,
  },
  card: {
    backgroundColor: '#5C7E96',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ececec',
    borderStyle: 'solid',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    marginVertical: useViewportDimensions(6, 'vh'),
    margin: 'auto',
    width: ' 90%',
  },
  card_body: {
    padding: 20,
  },
  card_text: {
    color: 'white',
    fontFamily: 'asap-regular',
    fontSize: 15,
    marginTop: 10,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  featuredImage: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: 200,
    margin: 'auto',
    marginBottom: 10,
    resizeMode: 'cover',
    width: '90%',
  },
  info: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  profilePicture: {
    borderRadius: 75,
    height: 150,
    marginBottom: 20,
    width: 150,
  },
  text1: {
    color: 'white',
    fontFamily: 'asap-regular',
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 10,
  },
});
