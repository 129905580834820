import { Dimensions } from 'react-native';

const Width = Dimensions.get('window').width;
const Height = Dimensions.get('window').height;

export default function useViewportDimensions(size, axis) {
  if (axis === 'vh') {
    return Height * (size / 100);
  }
  if (axis === 'vw') {
    return Width * (size / 100);
  }
}
