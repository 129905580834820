import React, { useState } from 'react';
import { View } from 'react-native';
import CustomButton from 'src/components/CustomButton';
import useViewportDimensions from 'src/integrations/useViewportDimensions';

export default function BinaryQuestion({ onChange, initialValue = null }) {
  const [selection, setSelection] = useState(initialValue);
  return (
    <View
      style={{
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        gap: useViewportDimensions(4, 'vw'),
        marginVertical: useViewportDimensions(6, 'vh'),
      }}>
      <CustomButton
        text="Si"
        type={selection === true ? 'PRIMARY' : 'SECONDARY'}
        onPress={() => {
          onChange(true);
          setSelection(true);
        }}
      />

      <CustomButton
        type={selection === false ? 'PRIMARY' : 'SECONDARY'}
        text="No"
        onPress={() => {
          setSelection(false);
          onChange(false);
        }}
      />
    </View>
  );
}
