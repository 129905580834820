import AsyncStorage from '@react-native-async-storage/async-storage';
import { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import ProgressBar from 'src/components/ProgressBar';
import { sendAnswersToApi } from 'src/integrations/API';
import BegekkoBreadCrumb from 'src/layout/BegekkoBreadCrumb';
import Layout from 'src/layout/Layout';
import Question from 'src/screens/gekko/questions/Question';
import CommonStyles from 'src/styles/CommonStyles';

const getFirstKey = (obj) => {
  const keys = Object.keys(obj);
  return keys[0];
};
const getLastKey = (obj) => {
  const keys = Object.keys(obj);
  return keys[keys.length - 1];
};

const getNextId = ({ id }) => String(parseInt(id) + 1);

const QuestionBlock = ({ content, setQuestionnaireQuestions }) => {
  return (
    <Question
      key={content.id}
      type={content.type}
      large
      onChange={(answer) => {
        answer = String(answer);
        setQuestionnaireQuestions((prevState) => {
          const newState = {
            ...prevState,
            [content.id]: {
              ...prevState[content.id],
              answer,
            },
          };
          return newState;
        });
      }}
      value={content.answer ? content.answer : ''}
    />
  );
};

export default function QuestionnaireQuestionsScreen({ route, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);
  const { questionnaire, key, requestId } = route.params;

  const [questionnaireQuestions, setQuestionnaireQuestions] = useState(questionnaire);

  const firstKey = getFirstKey(questionnaireQuestions);

  const [currentQuestionId, setCurrentQuestionId] = useState(firstKey);

  const [loading, setLoading] = useState(false);

  const latestUnansweredQuestion = (questionnaireObject) => {
    console.log('EEEEEEE', questionnaireObject);
    for (const prop in questionnaireObject) {
      if (
        questionnaireObject[prop].answer === null ||
        questionnaireObject[prop].answer === undefined
      ) {
        console.log('THe first q', questionnaireObject[prop].id);
        return questionnaireObject[prop].id;
      }
    }
  };

  useEffect(() => {
    (async () => {
      // DEBUG:
      // await AsyncStorage.removeItem(`@questionnaire_id_${requestId}_key_${key}`);
      let storedQuestionnaires = await AsyncStorage.getItem(
        `@questionnaire_id_${requestId}_key_${key}`
      );
      storedQuestionnaires = JSON.parse(storedQuestionnaires) ?? undefined;
      if (storedQuestionnaires) {
        const currentId = latestUnansweredQuestion(storedQuestionnaires);
        setQuestionnaireQuestions(storedQuestionnaires);
        setCurrentQuestionId(currentId);
      }
    })();
  }, []);

  const goNextQuestion = async () => {
    setLoading(true);

    try {
      await AsyncStorage.setItem(
        `@questionnaire_id_${requestId}_key_${key}`,
        JSON.stringify(questionnaireQuestions)
      );
    } catch (_) {
      return setLoading(false);
    }

    const questionObject = questionnaireQuestions[currentQuestionId];

    const nextId = getNextQuestion(questionObject);
    setCurrentQuestionId(nextId);
    setLoading(false);
  };

  const getNextQuestion = (questionObject) => {
    if (questionObject['follow-id']) {
      if (questionObject.type !== 'binary') {
        return questionObject['follow-id'];
      }
      if (questionObject.type === 'binary') {
        if (questionObject.answer === 'true') {
          // YES
          return questionObject['follow-id'];
        }
        if (questionObject.answer === 'false') {
          // NO
          return questionObject['follow-no-id'];
        }
      }
    }
    let nextId = getNextId({ id: questionObject.id });
    if (!questionnaireQuestions[nextId]) {
      nextId = latestUnansweredQuestion(questionnaireQuestions);
    }
    return nextId;
  };

  const getIterativeLastKey = (questionsObject) => {
    let lastQuestionNumber;
    console.log('try to get', questionsObject);
    for (const id in questionsObject) {
      if (questionsObject[id].dependencies.includes('dependencies')) {
        return lastQuestionNumber - 1;
      }
      console.log('id in', questionsObject[id].dependencies.includes('dependencies'));
      lastQuestionNumber = getNextQuestion(questionsObject[id]);
      console.log('nextQ:>', lastQuestionNumber);
    }
    return lastQuestionNumber - 1;
  };

  const lastKey = getLastKey(questionnaireQuestions); // getIterativeLastKey(questionnaireQuestions);
  const NextBlock = ({ navigation }) => {
    console.log(lastKey, currentQuestionId);

    if (!questionnaireQuestions[currentQuestionId].answer) {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
          {parseInt(currentQuestionId) !== parseInt(firstKey) && (
            <CustomButton
              type="SECONDARY"
              onPress={() => setCurrentQuestionId(String(parseInt(currentQuestionId) - 1))}
              text="< ANTERIOR"
            />
          )}
        </View>
      );
    }
    if (parseInt(currentQuestionId) === parseInt(lastKey)) {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
          {parseInt(currentQuestionId) !== parseInt(firstKey) && (
            <CustomButton
              type="SECONDARY"
              onPress={() => setCurrentQuestionId(String(parseInt(currentQuestionId) - 1))}
              text="< ANTERIOR"
            />
          )}
          <CustomButton
            type="PRIMARY"
            onPress={async () => {
              setLoading(true);
              try {
                await sendAnswersToApi({ answers: questionnaireQuestions, key, requestId });
              } catch (_) {
                return setLoading(false);
              }
              await AsyncStorage.removeItem(`@questionnaire_id_${requestId}_key_${key}`);

              setLoading(false);

              navigation.navigate('GekkoDashboardScreen');
            }}
            text="COMPLETAR"
            loading={loading}
          />
        </View>
      );
    }
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        {parseInt(currentQuestionId) !== parseInt(firstKey) && (
          <CustomButton
            type="SECONDARY"
            onPress={() => setCurrentQuestionId(String(parseInt(currentQuestionId) - 1))}
            text="< ANTERIOR"
          />
        )}
        <CustomButton type="PRIMARY" onPress={goNextQuestion} text="SIGUIENTE >" />
      </View>
    );
  };

  return (
    <Layout navigation={navigation}>
      <View>
        <BegekkoBreadCrumb text={key} />
        <ProgressBar current={currentQuestionId} max={lastKey} min={firstKey} />
        <View style={[styles.card, { marginTop: 50 }]}>
          <View style={styles.card_title}>
            <Text style={styles.card_title_text}>
              {questionnaireQuestions[currentQuestionId].question}
            </Text>
          </View>

          <View style={styles.card_footer}>
            <QuestionBlock
              key={currentQuestionId}
              content={questionnaireQuestions[currentQuestionId]}
              setQuestionnaireQuestions={setQuestionnaireQuestions}
            />
          </View>
        </View>
      </View>
      <NextBlock navigation={navigation} />
    </Layout>
  );
}

const styles = StyleSheet.create(CommonStyles);
