import React, { useEffect, useContext } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import GlobalContext from 'src/GlobalContext';
import CustomButton from 'src/components/CustomButton';
import BigHeading from 'src/components/headings/BigHeading';
import MediumHeading from 'src/components/headings/MediumHeading';
import useViewportDimensions from 'src/integrations/useViewportDimensions';
import Layout from 'src/layout/Layout';

import GekkoRequestsList from './components/GekkoRequestsList';
import CommonStyles from '../../styles/CommonStyles';

export default function GekkoDashboardScreen({ route, navigation, ...props }) {
  const { GlobalFunctions, state } = useContext(GlobalContext);

  useEffect(() => {
    GlobalFunctions.loadGekkoDashboard(state.userToken);
  }, [state.userToken]);

  return (
    <Layout navigation={navigation}>
      <View>
        <BigHeading text="Bienvenido, Gekko" />

        <View style={[styles.card, styles.profile]}>
          <Image
            source={{
              uri: !state.personalData?.avatar?.includes('profile_picture_placeholder')
                ? `https://begekko.com/images/users/${state.userData.id}/avatar/${state.personalData.avatar}`
                : `https://begekko.com/images/profile/profile_picture_placeholder.png`,
            }}
            style={styles.image}
          />
          <View style={{ paddingLeft: 20 }}>
            <Text style={[styles.text_base, styles.text_big]}>
              {state.userData.first_name} {state.userData.last_name}
            </Text>
            <Text style={[styles.text_base, styles.text_small]}>{state.personalData?.city}</Text>
          </View>
        </View>

        <View style={{ marginTop: 40 }} />

        <GekkoRequestsList key={navigation} navigation={navigation} />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  ...CommonStyles,
  ...{
    after: {
      alignSelf: 'center',
      backgroundColor: '#D6DF2A',
      height: useViewportDimensions(1, 'vh'),
      marginTop: useViewportDimensions(2, 'vh'),
      width: '66%',
    },
    text: {
      fontFamily: 'asap-regular',
      fontSize: 20,
      fontWeight: '500',
      marginBottom: 20,
      marginTop: 'auto',
    },
    title: {
      color: '#5C7E96',
      fontFamily: 'EchoesSans-Black',
      fontSize: 25,
      fontWeight: '500',
      lineHeigh: 1.2,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
});
