import axios from 'axios';
import { fakePendingQuestionnaires } from 'src/mock/fakePendingQuestionnaires';
import { fakeSendingAnswers } from 'src/mock/fakeSendingAnswers';

const API_ENDPOINT = 'https://begekko.com/api/mobile/';

export async function SendAnsweredQuestion(data) {
  console.log(data, 'intet post');
  const sendedData = {};
  await axios
    .post(
      API_ENDPOINT + 'submit-form',
      { data },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
    .then((result) => {
      console.log('data transmting intent result=', result);
    });
}
export async function getHotelsForm(userToken) {
  let pendingForms;

  await axios
    .post(
      API_ENDPOINT + 'pending-forms',
      {
        token: userToken,
      },
      {
        headers: { 'Content-Type': 'multipart/form-data', 'accept-language': 'es', Accept: '*/*' },
      }
    )
    .then((result) => {
      pendingForms = result;
      console.log(result, '-get hotels form from axios');
    })
    .catch((error) => {
      console.log(error, 'error');
      pendingForms = 'error';
    });
  return pendingForms;
}

export async function getHoteldata(userToken) {
  let resultScraper;
  console.log(userToken);
  await axios({
    method: 'post',
    url: 'https://begekko.com/api/mobile/scraper-info',
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    data: {
      token: userToken,
    },
  }).then((res) => {
    console.log('aded scraper', res);
    resultScraper = res;
  });

  return resultScraper;
}

export async function getForm(userToken) {
  let pendingForms;

  await axios
    .get(
      API_ENDPOINT + 'default-questionnaires',
      {
        token: userToken,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
          'Content-Language': 'es_LA',
          'Accept-Language': 'es_LA',
        },
      }
    )
    .then((result) => {
      pendingForms = result.data.default_questionnaires;

      pendingForms = { form: pendingForms };
      console.log(pendingForms, '-getting form template from server');
    });
  return pendingForms;
}

export async function sendNewForm(data) {
  console.log(data, 'sending new offer');

  await axios({
    method: 'post', //////here is error en method
    url: API_ENDPOINT + 'create-offer',
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    data: {
      departments: data.departments,
      professional_level: data.professional_level, ///"string",
      how_to_book: data.how_to_book, ///"string",
      mandatory_actions: data.mandatory_actions, //["string"],
      extra_values: data.extra_values,
      consec_nights: data.consec_nights, ///"string",
      consec_nights_disc: data.consec_nights_disc,
      custom_questions: data.custom_questions,
      date_limit: '08.06.2023', // ¿?¿?¿?¿?¿?¿
      hotel_id: data.hotel_id,
    },
  })
    .then((response) => {
      console.log(response, 'response form sent offer');
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function community(data) {
  const result = await axios({
    method: 'get',
    url: API_ENDPOINT + 'random-gekkos',
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
  });
  return result;
}

export async function getSignInAttempt(data) {
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'login',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  return result.data;
}

export async function getSignUpAttempt(data) {
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'signup',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  return result.data;
}

export async function getPendingQuestionnaires(token) {
  console.log(token);
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'pending-forms',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data: { token },
  });
  console.log(result.data);
  return result.data;
}

export async function sendQuestionnaireAnswersToAPI(data) {
  console.log(data);
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'submit-form',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  return result.data;
}

export async function getHotelList() {
  const result = await axios({
    method: 'get',
    url: API_ENDPOINT + 'hotel-list',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
  console.log(result.data);
  const parsedHotels = result.data
    .filter((hotel) => {
      if (hotel.photos.length === 0 || !hotel.address) {
        return false;
      }
      return true;
    })
    .map((hotel) => ({
      name: hotel.name,
      stars: hotel.stars,
      address: `${hotel.address?.address_1 || ''}${', ' + hotel.address?.street_number || ''}`,
      description:
        hotel.descriptions.filter((description) => description.lang_code === 'es')[0]
          ?.long_description || '',
      mainImage: `https://begekko.com/images/hotels/${hotel.slug}/${hotel.photos[0].name.replace(
        /\.[^.]*$/,
        '-xs$&'
      )}`,
      photos: hotel.photos || [],
      price: `${hotel.price_per_night_from}€ - ${hotel.price_per_night_to}€`,
      services: hotel.services
        .filter((service) => service.lang_code === 'es')
        .map((service) => service.name),
    }));
  return parsedHotels;
}

export async function getRequestsList(data) {
  console.log(data);
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'requests-list',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  console.log(result);
  return result.data;
}
export async function getPending(data) {
  console.log(data);
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'get-questionnaire',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  console.log(result.data);
  return result.data;
  //return fakePendingQuestionnaires.questionnaires
}
export async function sendAnswersToApi(data) {
  console.log(data);
  const result = await axios({
    method: 'post',
    url: API_ENDPOINT + 'set-questionnaire',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    data,
  });
  console.log(result.data);
  return result.data;
  //return fakePendingQuestionnaires.questionnaires
}
