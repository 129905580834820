import { View, Text, StyleSheet } from 'react-native';

export default function PillComponent({ text }) {
  return (
    <View style={styles.label_background}>
      <Text>{text}</Text>
    </View>
  );
}
const styles = StyleSheet.create({
  label_background: {
    backgroundColor: 'rgba(208, 220, 226, 0.3)',
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    fontSize: 14,
    margin: 10,
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    textAlign: 'center',
  },
});
