import Loader from 'assets/loading.svg';
import { Image } from 'react-native';
import NavBar from 'src/layout/NavBar';
export default function SplashScreen() {
  return (
    <>
      <NavBar isEmpty />
      <Image
        source={Loader}
        style={{ width: '100%', flex: 1, marginHorizontal: 'auto' }}
        resizeMode="contain"
      />
    </>
  );
}
